import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "canvas-tour"
}
const _hoisted_2 = { class: "canvas-tour-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.tourActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "btn icon-btn",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args))),
                "aria-label": "Sluit tour"
              }, [
                _createVNode(_component_Icon, { name: "close" })
              ]),
              _createElementVNode("button", {
                class: "btn icon-btn",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args))),
                "aria-label": "Vorige"
              }, [
                _createVNode(_component_Icon, { name: "up" })
              ]),
              _createElementVNode("button", {
                class: "btn icon-btn",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
                "aria-label": "Volgende"
              }, [
                _createVNode(_component_Icon, { name: "down" })
              ])
            ]),
            (_ctx.showSwipeHint)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 0,
                  class: "swipe-direction",
                  name: "upDownArrow"
                }))
              : _createCommentVNode("", true),
            (_ctx.showSwipeHint)
              ? (_openBlock(), _createBlock(_component_Icon, {
                  key: 1,
                  class: "help-icon",
                  name: "hand"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}