import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d413578"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptimizedImage = _resolveComponent("OptimizedImage")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.url,
    onMouseenter: _ctx.onMouseEnter,
    onMouseleave: _ctx.onMouseLeave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_OptimizedImage, {
        image: _ctx.item.image,
        width: 400,
        "instant-lazy": false
      }, null, 8, ["image"]),
      (_ctx.item.start)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.item.start) + " ", 1),
            (_ctx.item.end)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("- " + _toDisplayString(_ctx.item.end), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h3", null, _toDisplayString(_ctx.item.title), 1)
    ]),
    _: 1
  }, 8, ["to", "onMouseenter", "onMouseleave"]))
}