export * from '@pixi/constants';
export * from '@pixi/math';
export * from '@pixi/runner';
export * from '@pixi/settings';
export * from '@pixi/ticker';
import * as utils from '@pixi/utils';

export { utils };
export * from '@pixi/display';
export * from '@pixi/core';
export * from '@pixi/accessibility';
export * from '@pixi/app';
export * from '@pixi/graphics';
export * from '@pixi/interaction';
export * from '@pixi/sprite';

// Renderer plugins
import { Renderer } from '@pixi/core';
import { AccessibilityManager } from '@pixi/accessibility';

Renderer.registerPlugin('accessibility', AccessibilityManager);
import { BatchRenderer } from '@pixi/core';

Renderer.registerPlugin('batch', BatchRenderer);
import { InteractionManager } from '@pixi/interaction';

Renderer.registerPlugin('interaction', InteractionManager);

// Application plugins
import { Application } from '@pixi/app';
import { TickerPlugin } from '@pixi/ticker';

Application.registerPlugin(TickerPlugin);

// Filters
import { BlurFilter, BlurFilterPass } from '@pixi/filter-blur';

export const filters = {
  BlurFilter,
  BlurFilterPass
};
