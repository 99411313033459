import { MessageService } from '@/services/MessageService';
import { ContentfulService } from '@/services/ContentfulService';
import { IndexService } from '@/services/IndexService';
import { AdminService } from '@/services/AdminService';
import { MailService } from '@/services/MailService';

export const messageService = new MessageService();
export const contentfulService = new ContentfulService();
export const indexService = new IndexService();
export const adminService = new AdminService();
export const mailService = new MailService();
