import { Video } from './content';

export enum IndexType {
  Message = 'message',
  Tag = 'tag',
  Story = 'story'
}

export interface Image {
  alt: string;
  url: string;
  x: number;
  y: number;
  focusX?: number;
  focusY?: number;
}

export type Index = MessageIndex | TagIndex | StoryIndex;

export interface Message {
  id: string;
  from?: string;
  to?: string;
  title: string;
  created: string;
  updated: string;
  text?: string;
  date?: string;
  location?: string;
  email?: string;
  contactPermission?: boolean;
}

export interface MessageIndex {
  id: string;
  from?: string;
  to?: string;
  title: string;
  created: string;
  updated: string;
  text?: string;
  tags: TagReference[];
  tagIds: string[];
  type: IndexType.Message;
  isPersonal?: boolean;
}

export type NewMessage = Omit<Message, 'id' | 'created' | 'updated'>;
export type NewMessageIndex = Omit<MessageIndex, 'id'>;

export interface TagIndex {
  id: string;
  title: string;
  metaTitle?: string;
  metaDescription?: string;
  created: string;
  updated: string;
  introText: string;
  video?: Video;
  tagIds: string[];
  image: Image;
  tags: TagReference[];
  type: IndexType.Tag;
  isPersonal?: boolean;
  showOnMelati?: boolean;
}

export type NewTagIndex = Omit<TagIndex, 'id'>;
export type TagReference = Pick<TagIndex, 'id' | 'title'>;

export interface StoryIndex {
  id: string;
  title: string;
  metaTitle?: string;
  metaDescription?: string;
  person?: string;
  created: string;
  updated: string;
  introText: string;
  video?: Video;
  image: Image;
  tagIds: string[];
  type: IndexType.Story;
  tags: TagReference[];
  isPersonal?: boolean;
  showOnMelati?: boolean;
}

export type NewStoryIndex = Omit<StoryIndex, 'id'>;

export interface IndexConfig {
  lastRun: string;
}

export interface IndexQueryOptions {
  type?: IndexType;
  exclude?: string;
  tagIds?: string[];
  limit?: number;
  cursor?: string;
  filter?: string;
  filterDesc?: boolean;
}

export interface IndexQueryResponse {
  items: Index[];
  nextCursor?: string;
}

export interface StatisticsTotals {
  all: number;
  messages: number;
  stories: number;
  tags: number;
}

export interface OverviewIndex {
  indexes: Index[];
}
