import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = {
  key: 0,
  role: "menu"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["share-button", { reverse: _ctx.reverse }]),
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, [
    _createElementVNode("button", {
      class: "btn labeled",
      "aria-haspopup": "true",
      "aria-expanded": _ctx.show
    }, [
      _createVNode(_component_Icon, { name: "share" }),
      _createTextVNode("Deel")
    ], 8, _hoisted_1),
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onShare('twitter'))),
              href: _ctx.twitterShare(),
              class: "button",
              "aria-label": "twitter"
            }, [
              _createVNode(_component_Icon, { name: "twitter" })
            ], 8, _hoisted_3)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onShare('facebook'))),
              href: _ctx.facebookShare(),
              class: "button",
              "aria-label": "facebook"
            }, [
              _createVNode(_component_Icon, { name: "facebook" })
            ], 8, _hoisted_4)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onShare('whatsapp'))),
              href: _ctx.whatsappShare(),
              class: "button",
              "aria-label": "whats-app"
            }, [
              _createVNode(_component_Icon, { name: "whatsapp" })
            ], 8, _hoisted_5)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onShare('email'))),
              href: _ctx.emailShare(),
              class: "button",
              "aria-label": "email"
            }, [
              _createVNode(_component_Icon, { name: "email" })
            ], 8, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}