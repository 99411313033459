
import MelatiStep from '@/components/CreateMelati/MelatiStep.vue';
import StepFour from '@/components/CreateMelati/StepFour.vue';
import StepOne from '@/components/CreateMelati/StepOne.vue';
import StepThree from '@/components/CreateMelati/StepThree.vue';
import StepTwo from '@/components/CreateMelati/StepTwo.vue';
import { MelatiUnparsedMessage } from '@/model/data';
import router, { RouteName } from '@/router';
import { messageService } from '@/services';
import { useAuth } from '@/store/useAuth';
import { useMeta } from '@/store/useMeta';
import { useOverviewIndexes } from '@/store/useOverviewIndexes';
import { logInteractionEvent } from '@/utils/analytics';
import { separateTitleFromText } from '@/utils/string-utils';
import { gsap } from 'gsap';
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'Melati',
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    MelatiStep
  },
  setup() {
    useMeta({ title: 'Deel je gedachten', canonical: '/melati/new' });

    const melatiData = ref<MelatiUnparsedMessage>({
      title: ''
    });
    const currentStep = ref(1);
    const circle = ref<HTMLElement | undefined>(undefined);
    const circleAnimation = ref<undefined | GSAPTimeline>(undefined);
    const submitted = ref(false);
    const error = ref(false);
    const errorMessage = ref('');

    const { addPersonalMelati } = useOverviewIndexes();
    const { initAuth, signIn } = useAuth();

    const goToHome = () => {
      logInteractionEvent({
        page_type: 'melati',
        event_name: 'cancel',
        interaction_type: 'click'
      });
      router.push({ name: RouteName.Home });
    };

    const handleKeydown = async (e: KeyboardEvent) => {
      await nextTick();
      const key = e.key.toLowerCase();
      const target = e.target as HTMLButtonElement;
      if (
        !melatiData.value.title ||
        currentStep.value > 3 ||
        !key ||
        key !== 'enter' ||
        target.classList.contains('back-btn')
      )
        return;
      nextStep();
    };

    const previousStep = () => {
      currentStep.value--;

      logInteractionEvent({
        page_type: 'melati',
        event_name: 'previous_step',
        interaction_type: 'click',
        step: currentStep.value
      });
    };

    const nextStep = () => {
      if (currentStep.value == 1) {
        separateTitleFromText(melatiData.value);
      }
      currentStep.value++;

      logInteractionEvent({
        page_type: 'melati',
        event_name: 'next_step',
        interaction_type: 'click',
        step: currentStep.value
      });
    };

    onMounted(async () => {
      if (!circle.value) return;
      circleAnimation.value = gsap.timeline().to(circle.value, {
        x: 'random(-40, 200, 10)',
        y: 'random(-80, 50, 10)',
        duration: 10,
        ease: 'none',
        repeat: -1,
        repeatRefresh: true
      });

      await initAuth();
      await signIn();
    });

    watch(
      () => currentStep.value,
      () => {
        emailErrorMessage();
        if (!circle.value) return;
        if (currentStep.value === 4) {
          circleAnimation.value?.pause();
          gsap.to(circle.value, {
            xPercent: -50,
            yPercent: -50,
            left: '50%',
            top: '50%',
            x: 0,
            y: 0,
            duration: 5
          });
        } else {
          resumeAnimation(circle.value);
        }
        const vw = getViewWidthCircle();
        circle.value.style.width = vw;
        circle.value.style.height = vw;
      }
    );

    const getViewWidthCircle = () => {
      switch (currentStep.value) {
        case 1:
          return '80vw';
        case 2:
          return '70vw';
        case 3:
          return '50vw';
        case 4:
          return '80vw';
      }
      return '80vw';
    };

    const resumeAnimation = (value: HTMLElement) => {
      gsap.to(value, {
        xPercent: -0,
        yPercent: -0,
        left: '0%',
        top: '0%',
        duration: 5
      });
      circleAnimation.value?.play();
    };

    const submitMelati = async () => {
      submitted.value = true;
      try {
        const message = await messageService.createMessage(melatiData.value);
        localStorage.setItem('personalMelatiId', message.id);
        addPersonalMelati(message);
        await router.push({ name: RouteName.Home, query: { melatiId: message.id } });
      } catch (e) {
        error.value = true;
        errorMessage.value = 'Er is iets mis gegaan. Herlaad de pagina en probeer het opnieuw.';
      }
    };

    const invalidEmail = computed(() => {
      if (!melatiData.value.email) return false;
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !regex.test(String(melatiData.value.email).toLowerCase());
    });

    watch(
      () => invalidEmail.value,
      () => {
        emailErrorMessage();
      }
    );

    const emailErrorMessage = () => {
      if (invalidEmail.value && currentStep.value === 3) {
        error.value = true;
        errorMessage.value = 'Voer een geldig emailadres in';
      } else {
        error.value = false;
        errorMessage.value = '';
      }
    };

    const pageText = computed(() => {
      switch (currentStep.value) {
        case 1:
          return 'Deel je gedachten';
        case 2:
          return 'Maak het persoonlijk';
        case 3:
          return 'Ontvang je melati';
      }
      return '';
    });

    const charactersUsed = computed(() => (melatiData.value.message?.length ? melatiData.value?.message?.length : 0));

    return {
      goToHome,
      previousStep,
      nextStep,
      melatiData,
      currentStep,
      submitMelati,
      circle,
      submitted,
      error,
      errorMessage,
      invalidEmail,
      handleKeydown,
      pageText,
      charactersUsed
    };
  }
});
