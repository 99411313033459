
import { computed, defineComponent, PropType, ref } from 'vue';

import { getImgUrl } from '@/utils/image';
import { Image } from '@functions/model/firestore';
import LazyLoadInstance from '@/utils/lazyload';

export default defineComponent({
  props: {
    image: {
      type: Object as PropType<Image>,
      required: true
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    lazy: {
      type: Boolean,
      default: true
    },
    sqip: {
      type: Boolean,
      default: true
    },
    instantLazy: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const loaded = ref(!props.sqip);

    const webpUrl = computed(() => imgUrl(true));
    const jpgUrl = computed(() => imgUrl());
    const sqipUrl = computed(() => imgSqipUrl());
    const sqipWebpUrl = computed(() => imgSqipUrl(true));

    const objectPosition = computed(() => {
      if (props.image?.focusX !== undefined && props.image?.focusY !== undefined) {
        const centerX = props.image.x / 2;
        const centerY = props.image.y / 2;

        const offsetX = (props.image.focusX - centerX) / centerX;
        const offsetY = (props.image.focusY - centerY) / centerY;
        const relOffsetX = getRelativeOffset(offsetX);
        const relOffsetY = getRelativeOffset(offsetY);

        const x = Math.min(100, Math.max(0, relOffsetX));
        const y = Math.min(100, Math.max(0, relOffsetY));

        return `${x}% ${y}%`;
      } else {
        return '50% 50%';
      }
    });

    function getRelativeOffset(offset: number) {
      return offset > 0 ? 100 - (1 - Math.abs(offset)) * 50 : (1 - Math.abs(offset)) * 50;
    }

    function imgUrl(webp = false) {
      return getImgUrl(props.image.url, props.width, props.height, webp);
    }

    function imgSqipUrl(webp = false) {
      return getImgUrl(props.image.url, 20, 20, webp, 10);
    }

    function onLoad() {
      loaded.value = true;
    }

    function onSqipLoad() {
      LazyLoadInstance.lazyload.update();
    }

    return {
      loaded,
      webpUrl,
      sqipUrl,
      sqipWebpUrl,
      jpgUrl,
      objectPosition,
      onLoad,
      onSqipLoad
    };
  }
});
