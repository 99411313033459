import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bebf18d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "to-from" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, [
      _createTextVNode(" Melati "),
      (_ctx.melatiData.from)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "van " + _toDisplayString(_ctx.melatiData.from), 1))
        : _createCommentVNode("", true),
      (_ctx.melatiData.to)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " voor " + _toDisplayString(_ctx.melatiData.to), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.melatiData.title), 1),
    _createElementVNode("span", _hoisted_5, [
      _createVNode(_component_Icon, { name: "melati" })
    ]),
    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.melatiData.text), 1)
  ], 64))
}