import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoryDetail = _resolveComponent("StoryDetail")!
  const _component_TagDetail = _resolveComponent("TagDetail")!
  const _component_MelatiDetail = _resolveComponent("MelatiDetail")!

  return (_ctx.isStory)
    ? (_openBlock(), _createBlock(_component_StoryDetail, {
        key: 0,
        story: _ctx.index,
        initOpen: _ctx.initOpen,
        first: _ctx.first,
        onOpenDetail: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open-detail')))
      }, null, 8, ["story", "initOpen", "first"]))
    : (_ctx.isTag)
      ? (_openBlock(), _createBlock(_component_TagDetail, {
          key: 1,
          tag: _ctx.index,
          initOpen: _ctx.initOpen,
          first: _ctx.first,
          onOpenDetail: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-detail')))
        }, null, 8, ["tag", "initOpen", "first"]))
      : (_ctx.isMelati)
        ? (_openBlock(), _createBlock(_component_MelatiDetail, {
            key: 2,
            message: _ctx.index,
            initOpen: _ctx.initOpen,
            first: _ctx.first
          }, null, 8, ["message", "initOpen", "first"]))
        : _createCommentVNode("", true)
}