
import { defineComponent, PropType, computed } from 'vue';
import { marked } from 'marked';
import { TagReference } from '@functions/model/firestore';

export default defineComponent({
  props: {
    tags: {
      type: Array as PropType<TagReference[]>,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const taggedText = computed(() => {
      let text = props.text;
      // Check text for each of the tags, partly and case insensitive and replace match with markdown anchor tag
      props.tags.forEach(tag => {
        text = text.replace(new RegExp(`\\b${tag.title}\\b`, 'i'), match => `[${match}](/thema/${tag.id})`);
      });

      return marked.parse(text);
    });

    return {
      taggedText
    };
  }
});
