import { ref, computed, watch } from 'vue';

import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from '@/utils/local-storage';
import { loadMarketingScripts } from '@/utils/marketing-scripts';

const cookieConsent = ref(getLocalStorageItem<boolean>(LocalStorageKey.CookieConsent));

export function useCookieConsent() {
  const hasCookieConsentValue = computed(() => cookieConsent.value !== undefined);

  function setCookieConsent(consent: boolean) {
    cookieConsent.value = consent;
  }

  watch(cookieConsent, (value, oldValue) => {
    setLocalStorageItem(LocalStorageKey.CookieConsent, value);

    if (value) {
      loadMarketingScripts();
    }

    if (!value && oldValue) {
      window.location.reload();
    }
  });

  return {
    cookieConsent,
    hasCookieConsentValue,
    setCookieConsent
  };
}
