import { ref, computed } from 'vue';
import { logInteractionEvent } from '@/utils/analytics';
import { SessionStorageKey, setSessionStorageItem } from '@/utils/session-storage';

export enum HomePageState {
  Intro = 'intro',
  Quotes = 'quotes',
  Counter = 'counter',
  Melati = 'melati'
}

const homePageState = ref<string>(HomePageState.Intro);

export function useHomePageState() {
  function setHomePageState(val: string) {
    homePageState.value = val;

    if (val === HomePageState.Counter) {
      logInteractionEvent({
        page_type: 'home',
        event_name: 'finish_intro'
      });
    }

    if (val === HomePageState.Melati) {
      setSessionStorageItem(SessionStorageKey.IntroSeen, true);
    }
  }

  const isIntro = computed(() => homePageState.value === HomePageState.Intro);
  const isQuotes = computed(() => homePageState.value === HomePageState.Quotes);
  const isCounter = computed(() => homePageState.value === HomePageState.Counter);
  const isMelati = computed(() => homePageState.value === HomePageState.Melati);

  return {
    homePageState,
    isIntro,
    isQuotes,
    isCounter,
    isMelati,
    setHomePageState
  };
}
