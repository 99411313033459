
import { Index, IndexType } from '@functions/model/firestore';
import { computed, defineComponent, PropType } from 'vue';
import StoryDetail from './StoryDetail.vue';
import MelatiDetail from './MelatiDetail.vue';
import TagDetail from './TagDetail.vue';

export default defineComponent({
  emits: ['open-detail'],
  props: {
    index: {
      type: Object as PropType<Index>,
      required: true
    },
    initOpen: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StoryDetail,
    MelatiDetail,
    TagDetail
  },
  setup(props) {
    const isStory = computed(() => {
      return props?.index?.type === IndexType.Story;
    });

    const isMelati = computed(() => {
      return props?.index?.type === IndexType.Message;
    });

    const isTag = computed(() => {
      return props?.index?.type === IndexType.Tag;
    });

    return {
      isStory,
      isMelati,
      isTag
    };
  }
});
