
import { useHome } from '@/store/useHome';
import { HomePageState, useHomePageState } from '@/store/useHomePageState';

import Initiator from '@/components/Initiator.vue';
import { usePreferences } from '@/store/usePreferences';
import { gsap } from 'gsap';

import { defineComponent, nextTick, onMounted, ref } from 'vue';

export default defineComponent({
  components: {
    Initiator
  },
  setup() {
    const init = ref<boolean>(false);
    const tweens = ref<Array<any>>([]);
    const showInitiator = ref(false);

    const { prefersReducedMotion } = usePreferences();
    const { home } = useHome();
    const { setHomePageState } = useHomePageState();

    onMounted(startAnimation);

    function floatAnimation() {
      // index to use for different durations
      let n = 0;
      const imagesCount = home.value?.page.images.length || 10;

      gsap.utils.toArray('.floating > .img').forEach((box: any) => {
        const tween = gsap.fromTo(
          box,
          { y: '100vh' },
          {
            duration: 44 + (imagesCount - n),
            delay: (imagesCount - n) * 15,
            ease: 'none',
            y: '-100vh',
            repeat: -1,
            repeatDelay: (imagesCount - n) * 15
          }
        );

        // Start tweens somewhere in the middle
        tween.seek(90);
        tween.pause();

        // if user prefers reduced motion pause the animation
        if (prefersReducedMotion.value) tween.pause();
        else tween.play();

        tweens.value.push(tween);

        n++;
      });
    }

    async function startAnimation() {
      if (!init.value) {
        await nextTick();

        floatAnimation();
        showInitiator.value = true;

        if (prefersReducedMotion.value) {
          gsap.to('.welcome-images', { opacity: 1, duration: 2, delay: 0.5, ease: 'none' });
        } else {
          gsap.to('.welcome-images', { opacity: 1, duration: 2, delay: 0.5, ease: 'none' });
          gsap.to('.welcome-text h1', {
            y: 0,
            opacity: 1,
            filter: 'blur(0px)',
            duration: 1.5,
            delay: 0.5,
            ease: 'none'
          });
          gsap.to('.welcome-text p', {
            y: 0,
            opacity: 1,
            filter: 'blur(0px)',
            duration: 1.5,
            delay: 1.5,
            ease: 'none'
          });
          gsap.fromTo(
            '.welcome .confirm-btn',
            { autoAlpha: 0 },
            { y: 0, autoAlpha: 1, duration: 1, delay: 3, ease: 'none' }
          );
        }
      }
      init.value = true;
    }

    function closeIntro() {
      setHomePageState(HomePageState.Quotes);
    }

    return {
      init,
      tweens,
      home,
      closeIntro,
      showInitiator
    };
  }
});
