
import { gsap } from 'gsap';
import { defineComponent, onMounted, ref } from 'vue';

import MelatiCounter from '@/components/Home/MelatiCounter.vue';

import { useHome } from '@/store/useHome';
import { HomePageState, useHomePageState } from '@/store/useHomePageState';
import { usePreferences } from '@/store/usePreferences';

import { useOverviewIndexes } from '@/store/useOverviewIndexes';

export default defineComponent({
  setup() {
    const tweens = ref<Array<any>>([]);

    const { prefersReducedMotion } = usePreferences();
    const { setHomePageState } = useHomePageState();
    const { home } = useHome();
    const { statisticsTotals } = useOverviewIndexes();

    const initCounter = () => {
      gsap.to('.canvas-counter', {
        y: -100,
        opacity: 1,
        filter: 'blur(0px)',
        duration: 1,
        ease: 'none',
        onStart: () => {
          setHomePageState(HomePageState.Counter);
        }
      });
      gsap.to('.canvas-counter', {
        y: -125,
        opacity: 0,
        filter: 'blur(10px)',
        duration: 2,
        delay: 6,
        ease: 'none',
        onComplete: () => {
          setHomePageState(HomePageState.Melati);
        }
      });
    };

    const startAnimations = () => {
      for (let n = 0; n < tweens.value.length; n++) {
        tweens.value[n].play();
      }
    };

    onMounted(async () => {
      if (!prefersReducedMotion.value) {
        startAnimations();
      }
      initCounter();
    });

    return {
      home,
      statisticsTotals,
      initCounter
    };
  },
  components: {
    MelatiCounter
  }
});
