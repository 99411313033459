import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7a0cde8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "selected-overlay"
}
const _hoisted_2 = { ref: "canvasContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptimizedImage = _resolveComponent("OptimizedImage")!
  const _component_CanvasSelectedContent = _resolveComponent("CanvasSelectedContent")!
  const _component_CanvasTour = _resolveComponent("CanvasTour")!
  const _component_CanvasInteractionHint = _resolveComponent("CanvasInteractionHint")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["canvas", { active: _ctx.active, locked: _ctx.locked }])
    }, [
      _createVNode(_TransitionGroup, {
        name: _ctx.first ? 'slide-fade-large' : 'fade'
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitedIndexes, (index) => {
            return _withDirectives((_openBlock(), _createBlock(_component_OptimizedImage, {
              class: "selected-image",
              key: index.id,
              image: index.image,
              width: 1600
            }, null, 8, ["image"])), [
              [_vShow, _ctx.selected && _ctx.selected.id === index.id]
            ])
          }), 128))
        ]),
        _: 1
      }, 8, ["name"]),
      _createVNode(_Transition, {
        name: _ctx.first ? 'slide-fade-large' : 'fade'
      }, {
        default: _withCtx(() => [
          (_ctx.selected && _ctx.selected.type === 'message')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["name"]),
      _createElementVNode("div", _hoisted_2, null, 512),
      _createVNode(_component_CanvasSelectedContent, {
        selected: _ctx.selected,
        first: _ctx.first
      }, null, 8, ["selected", "first"]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.selected && _ctx.position && !_ctx.tourActive)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "selected-tooltip",
                style: _normalizeStyle({ left: _ctx.position.x + 30 + 'px', top: _ctx.position.y + 20 + 'px' })
              }, " Bekijk ", 4))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2),
    (_ctx.canvas)
      ? (_openBlock(), _createBlock(_component_CanvasTour, {
          key: 0,
          canvas: _ctx.canvas,
          el: _ctx.canvasContainer,
          onInteracted: _ctx.hideCanvasHint
        }, null, 8, ["canvas", "el", "onInteracted"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.interactable && _ctx.showCanvasHint)
          ? (_openBlock(), _createBlock(_component_CanvasInteractionHint, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}