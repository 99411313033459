
import { contentfulService } from '@/services';
import { defineComponent, ref } from 'vue';
import { useMeta } from '@/store/useMeta';
import { Sitemap } from '@functions/model/sitemap';

export default defineComponent({
  async setup() {
    const sitemap = ref<Sitemap | null>(null);

    sitemap.value = await contentfulService.getSitemap();

    useMeta({
      title: sitemap.value?.title,
      canonical: `/sitemap`
    });

    return {
      sitemap
    };
  }
});
