import { firebase } from '@/utils/firebase';

export const initAnalytics = () => {
  firebase.analytics();
};

export const logEvent = (eventName: string, eventParams?: { [p: string]: any } | undefined) => {
  firebase.analytics().logEvent(eventName, eventParams);
};

export const logInteractionEvent = (eventParams: { page_type: string; event_name: string } & { [p: string]: any }) => {
  logEvent('user_interaction', eventParams);
};
