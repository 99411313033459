import { Index, IndexQueryOptions, IndexQueryResponse, StatisticsTotals } from '@functions/model/firestore';

export class IndexService {
  private apiUrl: string = process.env.VUE_APP_API_URL as string;

  public async getOverviewData(): Promise<{ indexes: Index[]; statisticsTotals: StatisticsTotals }> {
    const response = await fetch(`${this.apiUrl}/app/overview`);
    return await response.json();
  }

  public async query(queryOptions?: IndexQueryOptions): Promise<IndexQueryResponse> {
    const response = await fetch(`${this.apiUrl}/app/query`, {
      method: 'post',
      body: JSON.stringify(queryOptions)
    });

    return await response.json();
  }

  public async getIndexById(id: string): Promise<Index> {
    const response = await fetch(`${this.apiUrl}/app/index/${id}`);
    return await response.json();
  }
}
