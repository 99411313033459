
import { defineComponent, PropType, ref, toRefs, onMounted, computed } from 'vue';
import gsap from 'gsap';

import RelatedContent from '@/components/Content/RelatedContent.vue';
import LeaveMelati from '@/components/Content/LeaveMelati.vue';
import ShareButton from '@/components/Content/ShareButton.vue';
import ContentMedia from '@/components/Content/ContentMedia.vue';

import { IndexType, StoryIndex, TagIndex } from '@functions/model/firestore';
import { useCanvasClicked } from '@/store/useCanvasClicked';
import { useScreen } from '@/store/useScreen';
import { RelatedContentResponse } from '@functions/model/content';

export default defineComponent({
  components: {
    RelatedContent,
    ShareButton,
    LeaveMelati,
    ContentMedia
  },
  props: {
    content: {
      type: Object as PropType<StoryIndex | TagIndex>,
      required: true
    },
    related: {
      type: Object as PropType<RelatedContentResponse>
    },
    initOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { content, initOpen } = toRefs(props);

    const detailIsOpen = ref(false);
    const { canvasClicked, setCanvasClicked } = useCanvasClicked();
    const { heightPx } = useScreen();

    onMounted(() => {
      if (initOpen.value) {
        if (canvasClicked.value) {
          setCanvasClicked(false);
          gsap
            .to('.detail', {
              duration: 0.5
            })
            .eventCallback('onComplete', () => {
              openContent();
            });
        } else {
          openContent();
        }
      }
    });

    const onPlay = async () => {
      if (!detailIsOpen.value) {
        await openContent();
      }
    };

    const openContent = async () => {
      if (!detailIsOpen.value) {
        context.emit('open');
        detailIsOpen.value = true;
      }
    };

    const contentLabel = computed(() => (content.value.type === IndexType.Story ? 'verhaal' : 'thema'));
    const contentUrl = computed(() => `${window.location.origin}/${contentLabel.value}/${content.value.id}`);
    const containerStyle = computed(() => ({ height: detailIsOpen.value ? 'fit-content' : heightPx }));

    return {
      detailIsOpen,
      contentUrl,
      contentLabel,
      heightPx,
      containerStyle,
      openContent,
      onPlay
    };
  }
});
