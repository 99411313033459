import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Home from '@/views/Home.vue';
import Sitemap from '@/views/Sitemap.vue';
import Overview from '@/components/Content/Overview.vue';
import Melati from '@/views/Melati.vue';
import Timeline from '@/views/Timeline.vue';
import ContentPage from '@/views/ContentPage.vue';
import LandingPage from '@/views/LandingPage.vue';

import { IndexType } from '@functions/model/firestore';

export enum RouteName {
  Home = 'Monument',
  Sitemap = 'Sitemap',
  ContentPage = 'ContentPage',
  LandingPage = 'LandingPage',
  Tag = 'Tag',
  Story = 'Story',
  Melati = 'Melati',
  NewMelati = 'NewMelati',
  Timeline = 'Tijdlijn',
  Admin = 'Admin'
}

export function getIndexRouteName(type: IndexType) {
  switch (type) {
    case IndexType.Message:
      return RouteName.Melati;
    case IndexType.Story:
      return RouteName.Story;
    case IndexType.Tag:
      return RouteName.Tag;
  }
}

export function getIndexRoutePath(type: IndexType) {
  switch (type) {
    case IndexType.Message:
      return 'melati';
    case IndexType.Story:
      return 'verhaal';
    case IndexType.Tag:
      return 'thema';
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RouteName.Home,
    component: Home
  },
  {
    path: '/sitemap',
    name: RouteName.Sitemap,
    component: Sitemap
  },
  {
    path: '/melati/new',
    name: RouteName.NewMelati,
    component: Melati
  },
  // *****************************
  // Important! The following routes must not change as we adhere to LOD and persistent urls
  {
    path: '/thema/:id?',
    name: RouteName.Tag,
    component: Overview
  },
  {
    path: '/verhaal/:id',
    name: RouteName.Story,
    component: Overview
  },
  {
    path: '/melati/:id',
    name: RouteName.Melati,
    component: Overview
  },
  // *****************************
  {
    path: '/content/:slug',
    name: RouteName.ContentPage,
    component: ContentPage
  },
  {
    path: '/landing/:slug',
    name: RouteName.LandingPage,
    component: LandingPage
  },
  {
    path: '/tijdlijn',
    name: RouteName.Timeline,
    component: Timeline
  },
  {
    path: '/admin',
    name: RouteName.Admin,
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RouteName.Home }
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
