import { computed, ref } from 'vue';
import { useTour } from '@/store/useTour';
import { useCustomRoute } from '@/store/useCustomRoute';
import { RouteName } from '@/router';
import { useHomePageState } from '@/store/useHomePageState';

const logo = ref(true);
const totals = ref(false);
const topMenu = ref(true);
const hamburger = ref(true);
const bottomMenu = ref(false);

const { tourActive } = useTour();
const { includesRoute } = useCustomRoute();
const { isIntro } = useHomePageState();

export function useInterfaceSettings() {
  const showLogo = computed(() => logo.value && !includesRoute([RouteName.NewMelati, RouteName.Admin]));
  const showTotals = computed(
    () =>
      totals.value &&
      !includesRoute([RouteName.NewMelati, RouteName.Admin, RouteName.Story, RouteName.Melati, RouteName.Tag])
  );
  const showTopMenu = computed(
    () =>
      topMenu.value &&
      !tourActive.value &&
      !isIntro.value &&
      !includesRoute([RouteName.NewMelati, RouteName.Story, RouteName.Tag, RouteName.Melati, RouteName.Admin])
  );
  const showHamburger = computed(
    () =>
      hamburger.value &&
      !includesRoute([RouteName.Story, RouteName.Tag, RouteName.Melati, RouteName.NewMelati, RouteName.Admin])
  );
  const showBottomMenu = computed(
    () =>
      bottomMenu.value &&
      !tourActive.value &&
      !includesRoute([
        RouteName.Story,
        RouteName.Tag,
        RouteName.Melati,
        RouteName.NewMelati,
        RouteName.Admin,
        RouteName.ContentPage,
        RouteName.Sitemap,
        RouteName.LandingPage
      ])
  );

  function setShowLogo(value: boolean) {
    logo.value = value;
  }

  function setShowTotals(value: boolean) {
    totals.value = value;
  }

  function setShowTopMenu(value: boolean) {
    topMenu.value = value;
  }

  function setShowBottomMenu(value: boolean) {
    bottomMenu.value = value;
  }

  function setShowHamburger(value: boolean) {
    hamburger.value = value;
  }

  function setShowAllMenus(value: boolean) {
    setShowTopMenu(value);
    setShowBottomMenu(value);
  }

  return {
    showLogo,
    showTotals,
    showTopMenu,
    showHamburger,
    showBottomMenu,
    setShowLogo,
    setShowTotals,
    setShowTopMenu,
    setShowHamburger,
    setShowBottomMenu,
    setShowAllMenus
  };
}
