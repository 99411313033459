
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true
    },
    reverse: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const show = ref(false);

    const facebookShare = () => {
      return `https://www.facebook.com/sharer/sharer.php?u=${props.url}`;
    };
    const twitterShare = () => {
      return `https://twitter.com/intent/tweet?url=${props.url}`;
    };
    const whatsappShare = () => {
      return `https://api.whatsapp.com/send?text=Dit wil ik heel graag met jou delen: ${props.url}`;
    };
    const emailShare = () => {
      return `mailto:?subject=Indisch Monument&body=Dit wil ik heel graag met jou delen ${props.url}.`;
    };

    const toggle = () => {
      show.value = !show.value;

      emit('toggle', show.value);
    };

    const onShare = (platform: string) => {
      emit('share', platform);
    };

    return {
      show,
      facebookShare,
      twitterShare,
      whatsappShare,
      emailShare,
      toggle,
      onShare
    };
  }
});
