import { ref } from 'vue';

const leaveTransitions = ref<Promise<void>[]>([]);

export function usePageTransition() {
  function registerPageLeaveTransition(promise: Promise<void>) {
    leaveTransitions.value.push(promise);
  }

  async function handleLeaveTransitions() {
    if (leaveTransitions.value.length === 0) {
      return;
    }

    await Promise.all(leaveTransitions.value);
    leaveTransitions.value = [];
  }

  return {
    registerPageLeaveTransition,
    handleLeaveTransitions
  };
}
