import { TweenMax, Power4 } from 'gsap';
import { Canvas } from '@/canvas/canvas';
import { useTour } from '@/store/useTour';

const { tourActive, setTourActive } = useTour();

const ZOOM_DURATION = 5;
const ZOOM_SCALE = 1.5;
const MOVE_DURATION = 2;

class CanvasTour {
  public readonly canvas: Canvas;

  public onStart: Function | null = null;
  public onStop: Function | null = null;

  private activePointIndex = 0;

  constructor(canvas: Canvas) {
    this.canvas = canvas;
  }

  public start(index = 0) {
    this.activePointIndex = index;
    this.move(true);

    setTourActive(true);
    this.onStart?.();
  }

  public previous() {
    this.deselectCurrent();

    if (this.activePointIndex === 0) {
      this.activePointIndex = this.canvas.pointController?.visiblePoints.length || 0;
    }

    this.activePointIndex--;
    this.move();
  }

  public next() {
    this.deselectCurrent();

    if (this.activePointIndex >= (this.canvas.pointController?.visiblePoints.length || 0) - 1) {
      this.activePointIndex = 0;
    }

    this.activePointIndex++;
    this.move();
  }

  public stop() {
    this.deselectCurrent();
    this.activePointIndex = 0;
    setTourActive(false);
    this.onStop?.();

    TweenMax.to(this.canvas.container, MOVE_DURATION, {
      pixi: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        scale: this.canvas.scale
      },
      ease: Power4.easeOut
    });
  }

  private move(first = false) {
    const point = this.canvas.pointController?.visiblePoints[this.activePointIndex];

    if (!point) {
      return;
    }

    const { x, y } = point.targetPos;

    TweenMax.to(this.canvas.container, tourActive.value ? MOVE_DURATION : ZOOM_DURATION, {
      pixi: {
        x: window.innerWidth / 2 + x * -ZOOM_SCALE,
        y: window.innerHeight / 2 + y * -ZOOM_SCALE,
        scale: ZOOM_SCALE
      },
      ease: Power4.easeOut
    });

    setTimeout(() => {
      point.select(first);
    }, 300);
  }

  private deselectCurrent() {
    this.canvas.pointController?.visiblePoints[this.activePointIndex].deselect();
  }
}

export { CanvasTour };
