import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["tabindex"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.newMelatiData)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(" Ik heb dit geschreven voor (optioneel) "),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMelatiData.to) = $event)),
            maxlength: "50",
            placeholder: "Naam",
            tabindex: _ctx.disable ? '-1' : '0',
            ref: "focus"
          }, null, 8, _hoisted_2), [
            [_vModelText, _ctx.newMelatiData.to]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.newMelatiData)
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
          _createTextVNode(" Mijn naam is (optioneel) "),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newMelatiData.from) = $event)),
            maxlength: "50",
            placeholder: "Naam",
            tabindex: _ctx.disable ? '-1' : '0'
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.newMelatiData.from]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}