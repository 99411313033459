export enum SessionStorageKey {
  IntroSeen = 'vim:intro_seen'
}

export function getSessionStorageItem<T>(key: SessionStorageKey): T | undefined {
  try {
    const value = sessionStorage.getItem(key);

    if (!value) {
      return;
    }

    return JSON.parse(value) as T;
  } catch (e) {
    console.error(`Failed to get session storage data with key '${key}'`, e);
  }
}

export function setSessionStorageItem(key: SessionStorageKey, value: unknown) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(`Failed to set session storage data with key '${key}' and value: ${value}`, e);
  }
}
