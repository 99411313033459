
import { computed, defineComponent, PropType, ref } from 'vue';
import { TagReference } from '@functions/model/firestore';

export default defineComponent({
  props: {
    tags: {
      type: Array as PropType<TagReference[]>,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'p'
    }
  },
  setup(props) {
    const output = ref<any>([]);
    const hasTags = computed(() => props.tags.length > 0);

    if (props.tags.length > 0) {
      const tagNames = props.tags.map(tag => tag.title);
      // Create a regex that contains all the relevant tags for this text
      const regex = new RegExp(`(${tagNames.join('|')})`, 'ig');

      // Split string by regular texts and tags in order to mark it as a link or plain old text
      output.value = props.text.split(regex).map(part => {
        const tag = props.tags.find(tag => tag.title.toLowerCase() === part.toLowerCase());
        return {
          tag: !!tag,
          text: tag?.title || part,
          id: tag?.id
        };
      });
    }

    return {
      output,
      hasTags
    };
  }
});
