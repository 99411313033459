import { Timeline } from '@functions/model/timeline';
import { ContentPage, HomeResponse, StoryResponse, TagResponse } from '@functions/model/content';
import { Sitemap } from '@functions/model/sitemap';
import { LandingPage } from '@functions/model/landing-page';

export class ContentfulService {
  private apiUrl: string = process.env.VUE_APP_API_URL as string;

  public async getStoryById(id: string): Promise<StoryResponse | null> {
    const response = await fetch(`${this.apiUrl}/app/story/${id}`);
    return await response.json();
  }

  public async getSubTagById(id: string): Promise<TagResponse | null> {
    const response = await fetch(`${this.apiUrl}/app/tag/${id}`);
    return await response.json();
  }

  public async getTimeline(): Promise<Timeline | null> {
    const response = await fetch(`${this.apiUrl}/app/timeline`);
    return await response.json();
  }

  public async getSitemap(): Promise<Sitemap | null> {
    const response = await fetch(`${this.apiUrl}/app/sitemap`);
    return await response.json();
  }

  public async getContentPageBySlug(slug: string): Promise<ContentPage | null> {
    const response = await fetch(`${this.apiUrl}/app/content/${slug}`);
    return await response.json();
  }

  public async getLandingPageBySlug(slug: string): Promise<LandingPage | null> {
    const response = await fetch(`${this.apiUrl}/app/landing/${slug}`);
    return await response.json();
  }

  public async getHome(): Promise<HomeResponse | null> {
    const response = await fetch(`${this.apiUrl}/app/home`);
    return await response.json();
  }
}
