
import { Melati } from '@/model/data';
import { logInteractionEvent } from '@/utils/analytics';
import { computed, defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    stepNumber: {
      type: Number,
      required: true
    },
    submitted: {
      type: Boolean
    },
    disableButton: {
      type: Boolean
    },
    melati: {
      type: Object as PropType<Melati>
    },
    charactersUsed: {
      type: Number
    }
  },
  emits: ['next', 'previous'],
  setup(props) {
    const focus = ref<null | HTMLInputElement>(null);

    const submitButtonAriaLabel = computed(() => {
      if (props.currentStep !== 4) return;
      const from = props.melati?.from ? `van ${props.melati?.from}` : '';
      const to = props.melati?.to ? `voor ${props.melati?.to}` : '';
      const title = props.melati?.title;
      const text = props.melati?.text ? props.melati?.text : '';
      return `Plaats nu je melati ${from} ${to} ${title} ${text}`;
    });

    const onSubmit = () => {
      if (!props.melati) {
        return;
      }

      const { title, text, from, to, date, location, email, contactPermission } = props.melati;

      logInteractionEvent({
        page_type: 'melati',
        event_name: 'submit_melati',
        interaction_type: 'click',
        has_title: !!title,
        has_text: !!text,
        has_from: !!from,
        has_to: !!to,
        has_date: !!date,
        has_location: !!location,
        has_email: !!email,
        has_contact_permission: !!contactPermission
      });
    };

    watch(
      () => props.currentStep,
      current => {
        setTimeout(() => {
          if (current === 4) focus.value?.focus();
        }, 1000);
      }
    );

    return {
      submitButtonAriaLabel,
      focus,
      onSubmit
    };
  }
});
