import { ref } from 'vue';

const tourActive = ref(false);

export function useTour() {
  function setTourActive(value: boolean) {
    tourActive.value = value;
  }

  return {
    tourActive,
    setTourActive
  };
}
