
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref } from 'vue';
import _debounce from 'lodash/debounce';

import TimelinePeriod from '@/components/Timeline/TimelinePeriod.vue';
import TimelineSlider from '@/components/Timeline/TimelineSlider.vue';
import TimelineScrollhint from '@/components/Timeline/TimelineScrollhint.vue';

import { AnimatedTimeline, createTimelineItemId } from '@/components/Timeline/timeline';
import LazyLoadInstance from '@/utils/lazyload';
import { useTimeline } from '@/store/useTimeline';
import { useMeta } from '@/store/useMeta';
import { clamp } from '@/utils/math';

const ITEM_SCROLL_HEIGHT = clamp(window.innerWidth, 400, 1200);

export default defineComponent({
  components: {
    TimelineSlider,
    TimelinePeriod,
    TimelineScrollhint
  },
  setup() {
    useMeta({ title: 'Tijdlijn', canonical: '/tijdlijn' });

    const currentPeriodIndex = ref(0);
    const timelineItemIds = ref<string[]>([]);
    const containerEl = ref<HTMLDivElement | null>(null);
    const scrollPercentage = ref(0);
    const containerHeight = ref<number>(0);
    const scrolling = ref(false);
    const initialized = ref(false);
    const interacted = ref(false);

    const { timeline: timelineData, getTimeline } = useTimeline();
    const timeline = new AnimatedTimeline();

    const start = computed(() => timelineData.value?.periods[0]?.start);
    const end = computed(() => {
      const lastPeriod = timelineData.value?.periods[timelineData.value?.periods.length - 1];
      return lastPeriod?.end || lastPeriod?.start;
    });

    document.addEventListener('scroll', (e: Event) => {
      if (initialized.value) {
        interacted.value = true;
      }

      e.preventDefault();

      if (containerEl.value) {
        scrollPercentage.value = window.scrollY / (containerEl.value.scrollHeight - window.innerHeight);
      }

      timeline.tl.progress(scrollPercentage.value);
    });

    async function onSlide(e: InputEvent) {
      scrolling.value = true;
      currentPeriodIndex.value = parseInt((e.target as HTMLInputElement).value) || 0;

      await timeline.tweenToPeriod(currentPeriodIndex.value);

      updateScrollPosition();
      scrolling.value = false;
    }

    function onPeriodEnter(periodIndex: number) {
      if (!scrolling.value) {
        currentPeriodIndex.value = periodIndex;
      }
    }

    function onItemsEnter(itemIds: string[]) {
      itemIds.forEach(id => document.querySelector(`${id} .original > img`)?.classList.add('lazy'));
      LazyLoadInstance.lazyload.update();
    }

    function onInit() {
      updateScrollPosition();

      setTimeout(() => {
        initialized.value = true;
      }, 1000);
    }

    function updateScrollPosition() {
      window.scroll(0, ((containerEl.value?.scrollHeight || 0) - window.innerHeight) * timeline.tl.progress());
    }

    const debouncedResize = _debounce(() => timeline.resize(), 300);

    onMounted(async () => {
      if (!timelineData.value) {
        await getTimeline();
      }

      if (!timelineData.value) {
        return;
      }

      // Wait for vue to render timeline periods and items in dom.
      await nextTick();

      const contentItemCount = timelineData.value?.periods.reduce((acc, period) => acc + period.content.length, 0);
      containerHeight.value = contentItemCount * ITEM_SCROLL_HEIGHT;
      timelineItemIds.value = timelineData.value?.periods.reduce((acc: string[], period) => {
        acc.push(...period.content.map(item => createTimelineItemId(period.id, item.id)));
        return acc;
      }, []);

      timeline.init(timelineData.value.periods);
      timeline.on('period-enter', onPeriodEnter);
      timeline.on('items-enter', onItemsEnter);
      timeline.on('init', onInit);
      window.addEventListener('resize', debouncedResize);

      LazyLoadInstance.lazyload.update();
    });

    onUnmounted(() => {
      timeline.off('period-enter', onPeriodEnter);
      timeline.off('items-enter', onItemsEnter);
      timeline.off('init', onInit);
      window.removeEventListener('resize', debouncedResize);
    });

    return {
      timelineData,
      currentPeriodIndex,
      containerEl,
      containerHeight,
      start,
      end,
      interacted,
      onSlide
    };
  }
});
