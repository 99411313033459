import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3646f214"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "optimized-image" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["srcset"]
const _hoisted_5 = ["src", "alt", "title"]
const _hoisted_6 = ["data-srcset", "srcset"]
const _hoisted_7 = ["data-srcset", "srcset"]
const _hoisted_8 = ["data-src", "src", "alt", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (!_ctx.loaded && _ctx.sqip)
          ? (_openBlock(), _createElementBlock("picture", _hoisted_2, [
              _createElementVNode("source", {
                srcset: _ctx.sqipWebpUrl,
                type: "image/webp"
              }, null, 8, _hoisted_3),
              _createElementVNode("source", {
                srcset: _ctx.sqipUrl,
                type: "image/jpeg"
              }, null, 8, _hoisted_4),
              (!_ctx.loaded)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "sqip",
                    src: _ctx.sqipUrl,
                    alt: _ctx.image.alt,
                    title: _ctx.image.alt,
                    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSqipLoad && _ctx.onSqipLoad(...args)))
                  }, null, 40, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("picture", {
      class: _normalizeClass(["original", { loaded: _ctx.loaded }])
    }, [
      _createElementVNode("source", {
        "data-srcset": _ctx.webpUrl,
        srcset: !_ctx.lazy ? _ctx.webpUrl : undefined,
        type: "image/webp"
      }, null, 8, _hoisted_6),
      _createElementVNode("source", {
        "data-srcset": _ctx.jpgUrl,
        srcset: !_ctx.lazy ? _ctx.jpgUrl : undefined,
        type: "image/jpeg"
      }, null, 8, _hoisted_7),
      _createElementVNode("img", _mergeProps({
        class: _ctx.instantLazy && _ctx.lazy ? 'lazy' : '',
        "data-src": _ctx.jpgUrl,
        src: !_ctx.lazy ? _ctx.jpgUrl : undefined,
        alt: _ctx.image.alt,
        title: _ctx.image.alt,
        onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args))),
        style: { objectPosition: _ctx.objectPosition }
      }, _ctx.$attrs), null, 16, _hoisted_8)
    ], 2)
  ]))
}