
import { defineComponent, ref, PropType } from 'vue';

import AnimatedMelatiText from '../Content/AnimatedMelatiText.vue';
import { Index } from '@functions/model/firestore';
import { useTour } from '@/store/useTour';
import { emitter, Event } from '@/utils/events';

export default defineComponent({
  components: {
    AnimatedMelatiText
  },
  props: {
    selected: {
      type: Object as PropType<Index | null>
    },
    first: {
      type: Boolean
    }
  },
  setup(props) {
    const fadeTransitionEnded = ref<boolean>(false);
    const letterTransitionEnded = ref<boolean>(false);

    const { tourActive, setTourActive } = useTour();

    const openContent = () => {
      emitter.emit(Event.ClickPoint, { data: props.selected });
      setTourActive(false);
    };

    return {
      fadeTransitionEnded,
      letterTransitionEnded,
      tourActive,
      openContent
    };
  }
});
