
import { defineComponent, PropType } from 'vue';

import TimelineItem from '@/components/Timeline/TimelineItem.vue';

import { TimelinePeriod as ITimelinePeriod } from '@functions/model/timeline';
import { getPeriodLabel } from '@/components/Timeline/timeline';

export default defineComponent({
  components: {
    TimelineItem
  },
  props: {
    period: {
      type: Object as PropType<ITimelinePeriod>
    }
  },
  setup() {
    return {
      getPeriodLabel
    };
  }
});
