import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Suspense as _Suspense, withCtx as _withCtx, Transition as _Transition, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Meta = _resolveComponent("Meta")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BottomMenu = _resolveComponent("BottomMenu")!
  const _component_CookieBar = _resolveComponent("CookieBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Meta),
    _createVNode(_component_Menu),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          onEnter: _ctx.enterTransition,
          onAfterEnter: _ctx.afterEnterTransition,
          onLeave: _ctx.leaveTransition,
          onAfterLeave: _ctx.afterLeaveTransition,
          css: false,
          appear: false
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock("main", { key: Component }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]))
              ]),
              fallback: _withCtx(() => [
                _createVNode(_component_Loader, {
                  loading: true,
                  light: ""
                })
              ]),
              _: 2
            }, 1024))
          ]),
          _: 2
        }, 1032, ["onEnter", "onAfterEnter", "onLeave", "onAfterLeave"])
      ]),
      _: 1
    }),
    _createVNode(_component_BottomMenu),
    (!_ctx.isDevelopment)
      ? (_openBlock(), _createBlock(_component_CookieBar, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}