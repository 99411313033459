
import { defineComponent, ref, watch } from 'vue';
import { TweenLite, Power3 } from 'gsap';
import { usePreferences } from '@/store/usePreferences';
import { useHomePageState } from '@/store/useHomePageState';

export default defineComponent({
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const count = ref<number>(0);
    const duration = ref<number>(5);
    const { prefersReducedMotion } = usePreferences();
    const { homePageState, isCounter } = useHomePageState();

    const setCount = () => {
      const obj = { n: count.value };
      TweenLite.to(obj, duration.value, {
        n: props.total,
        roundProps: 'n',
        onUpdate: () => {
          count.value = obj.n;
        },
        ease: Power3.easeOut
      });
    };

    watch(
      () => homePageState.value,
      () => {
        if (isCounter.value) {
          setCount();
        }
      }
    );

    return {
      count,
      prefersReducedMotion
    };
  }
});
