export enum LocalStorageKey {
  CookieConsent = 'vim:cookie_consent'
}

export function getLocalStorageItem<T>(key: LocalStorageKey): T | undefined {
  try {
    const value = localStorage.getItem(key);

    if (!value) {
      return;
    }

    return JSON.parse(value) as T;
  } catch (e) {
    console.error(`Failed to get local storage data with key '${key}'`, e);
  }
}

export function setLocalStorageItem(key: LocalStorageKey, value: unknown) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(`Failed to set local storage data with key '${key}' and value: ${value}`, e);
  }
}
