import firebase from 'firebase/app';
import { IndexType, MessageIndex } from '@functions/model/firestore';

import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import DocumentData = firebase.firestore.DocumentData;
import Firestore = firebase.firestore.Firestore;
import { Ref } from 'vue';

export class AdminService {
  private firestore: Firestore | undefined;

  public async init() {
    await import(/* webpackChunkName: "firebase-firestore" */ 'firebase/firestore');

    this.firestore = firebase.firestore();
  }

  public async deleteMessage(id: string): Promise<void> {
    await this.firestore?.collection('messages').doc(id).delete();
  }

  public async getMessageIndexes(
    limit: number,
    cursor: Ref<QueryDocumentSnapshot<DocumentData> | undefined>
  ): Promise<MessageIndex[]> {
    if (!this.firestore) {
      return [];
    }

    let query = this.firestore.collection('indexes').orderBy('created', 'desc').where('type', '==', IndexType.Message);

    if (cursor.value) {
      query = query.startAfter(cursor.value);
    }

    const snap = await query.limit(limit).get();
    const indexes: MessageIndex[] = [];
    snap.forEach((doc: any) => indexes.push({ id: doc.id, ...doc.data() } as MessageIndex));

    cursor.value = snap.docs[snap.docs.length - 1];

    return indexes;
  }

  public async getAllMessageIndexes(): Promise<MessageIndex[]> {
    if (!this.firestore) {
      return [];
    }

    const snap = await this.firestore
      .collection('indexes')
      .orderBy('created', 'desc')
      .where('type', '==', IndexType.Message)
      .get();
    const indexes: MessageIndex[] = [];
    snap.forEach((doc: any) => indexes.push({ id: doc.id, ...doc.data() } as MessageIndex));

    return indexes;
  }
}
