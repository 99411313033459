import { Message, NewMessage } from '@functions/model/firestore';
import { useAuth } from '@/store/useAuth';

export class MessageService {
  private apiUrl: string = process.env.VUE_APP_API_URL as string;

  public async createMessage(message: NewMessage): Promise<Message> {
    const { auth } = useAuth();
    const token = await auth?.currentUser?.getIdToken();

    if (!token) {
      throw new Error('No token.');
    }

    const response = await fetch(`${this.apiUrl}/app/message`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(message)
    });
    if (response.status !== 200) throw new Error();

    return await response.json();
  }
}
