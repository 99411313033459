
import { MelatiUnparsedMessage } from '@/model/data';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<MelatiUnparsedMessage>,
      required: true
    },
    disable: {
      type: Boolean
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const newMelatiData = computed({
      get: () => {
        return props.modelValue;
      },
      set: (newValue: MelatiUnparsedMessage) => {
        emit('update: modelValue', newValue);
      }
    });

    const totalCharacters = computed(() => {
      return newMelatiData.value.message?.length ?? 0;
    });

    const msgMaxLength = 400;

    const msgError = computed(() => {
      if (totalCharacters.value >= msgMaxLength) {
        return 'Je melati mag maximaal 400 karakters bevatten.';
      }
      return '';
    });

    return {
      newMelatiData,
      totalCharacters,
      msgError,
      msgMaxLength
    };
  }
});
