import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "detail-content"
}
const _hoisted_2 = {
  key: 0,
  class: "person"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "source" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_TaggedMarkdown = _resolveComponent("TaggedMarkdown")!
  const _component_TextBlock = _resolveComponent("TextBlock")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ContentDetail = _resolveComponent("ContentDetail")!

  return (_openBlock(), _createBlock(_component_ContentDetail, {
    content: _ctx.story,
    related: _ctx.fullStory?.related,
    "init-open": _ctx.initOpen,
    onOpen: _ctx.onOpenContent
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Loader, {
        loading: _ctx.loading,
        absolute: ""
      }, null, 8, ["loading"]),
      _createVNode(_Transition, {
        name: "fade-delay",
        onAfterEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('open-detail')))
      }, {
        default: _withCtx(() => [
          (_ctx.fullStory?.story)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("header", null, [
                  (_ctx.fullStory.story.person)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Het verhaal van " + _toDisplayString(_ctx.fullStory.story.person), 1))
                    : _createCommentVNode("", true),
                  (_ctx.first)
                    ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.story.title), 1))
                    : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.story.title), 1))
                ]),
                _createVNode(_component_TaggedMarkdown, {
                  class: "intro",
                  text: _ctx.story.introText,
                  tags: _ctx.story.tags
                }, null, 8, ["text", "tags"]),
                (_ctx.fullStory.story.textWithImage)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.fullStory.story.textWithImage, (textBlock, index) => {
                      return (_openBlock(), _createBlock(_component_TextBlock, {
                        key: index,
                        content: textBlock,
                        tags: _ctx.story.tags
                      }, null, 8, ["content", "tags"]))
                    }), 128))
                  : _createCommentVNode("", true),
                _createElementVNode("aside", _hoisted_5, [
                  (_ctx.fullStory.story.source)
                    ? (_openBlock(), _createBlock(_component_Markdown, {
                        key: 0,
                        text: `Bron: ${_ctx.fullStory.story.source}`
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["content", "related", "init-open", "onOpen"]))
}