
import { computed, defineComponent } from 'vue';

import close from '!html-loader!@/assets/icons/close.svg';
import search from '!html-loader!@/assets/icons/search.svg';
import swipe from '!html-loader!@/assets/icons/swipe.svg';
import down from '!html-loader!@/assets/icons/down.svg';
import up from '!html-loader!@/assets/icons/up.svg';
import menu from '!html-loader!@/assets/icons/menu.svg';
import instagram from '!html-loader!@/assets/icons/instagram.svg';
import facebook from '!html-loader!@/assets/icons/facebook.svg';
import twitter from '!html-loader!@/assets/icons/twitter.svg';
import expand from '!html-loader!@/assets/icons/expand.svg';
import melati from '!html-loader!@/assets/icons/melati.svg';
import melatiOutline from '!html-loader!@/assets/icons/melati-outline.svg';
import whatsapp from '!html-loader!@/assets/icons/whatsapp.svg';
import email from '!html-loader!@/assets/icons/email.svg';
import share from '!html-loader!@/assets/icons/share.svg';
import hover from '!html-loader!@/assets/icons/hover.svg';
import touch from '!html-loader!@/assets/icons/touch.svg';
import hand from '!html-loader!@/assets/icons/hand.svg';
import upDownArrow from '!html-loader!@/assets/icons/up-down-arrow.svg';

const icons: { [key: string]: string } = {
  close,
  search,
  swipe,
  down,
  up,
  menu,
  instagram,
  facebook,
  twitter,
  expand,
  melati,
  'melati-outline': melatiOutline,
  whatsapp,
  email,
  share,
  hover,
  touch,
  hand,
  upDownArrow
};

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const iconName = computed(() => `icon-${props.name}`);
    const icon = computed(() => icons[props.name]);

    return {
      iconName,
      icon
    };
  }
});
