import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3de93c3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timeline-slider" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "slider-input-wrapper" }
const _hoisted_4 = ["min", "max", "value"]
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.start)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.start), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        class: "slider-input",
        type: "range",
        min: _ctx.min,
        max: _ctx.max,
        value: _ctx.localPeriodIndex,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onChange: _cache[1] || (_cache[1] = e => _ctx.$emit('slide', e))
      }, null, 40, _hoisted_4),
      _createElementVNode("div", {
        class: "slider-period-label",
        style: _normalizeStyle(_ctx.getSliderPositionByIndex(_ctx.localPeriodIndex))
      }, _toDisplayString(_ctx.getPeriodLabel(_ctx.periods[_ctx.localPeriodIndex])), 5),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.waypoints, (waypoint) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "slider-waypoint",
          key: waypoint,
          style: _normalizeStyle(_ctx.getSliderPositionByIndex(waypoint))
        }, null, 4))
      }), 128))
    ]),
    (_ctx.end)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.end), 1))
      : _createCommentVNode("", true)
  ]))
}