import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f360b892"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["tabindex"]
const _hoisted_3 = {
  key: 1,
  class: "checkbox-label"
}
const _hoisted_4 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.newMelatiData)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(" Laat je e-mailadres achter en ontvang ook een link naar je eigen melati. "),
          _withDirectives(_createElementVNode("input", {
            type: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMelatiData.email) = $event)),
            placeholder: "E-mail",
            tabindex: _ctx.disable ? '-1' : '0',
            ref: "focus",
            autofocus: ""
          }, null, 8, _hoisted_2), [
            [_vModelText, _ctx.newMelatiData.email]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.newMelatiData)
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newMelatiData.contactPermission) = $event)),
            tabindex: _ctx.disable ? '-1' : '0'
          }, null, 8, _hoisted_4), [
            [_vModelCheckbox, _ctx.newMelatiData.contactPermission]
          ]),
          _createTextVNode(" Ik wil op de hoogte blijven van dit monument en ontvang graag de nieuwsbrief. ")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}