import { createApp } from 'vue';
import { gsap } from 'gsap/all';
import { PixiPlugin } from 'gsap/PixiPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import { filters, DisplayObject } from '@/lib/pixi';

import App from '@/App.vue';
import Icon from '@/components/Icon.vue';
import OptimizedImage from '@/components/OptimizedImage.vue';
import TaggedMarkdown from '@/components/TaggedMarkdown.vue';
import TaggedText from '@/components/TaggedText.vue';
import Markdown from '@/components/Markdown.vue';
import Loader from '@/components/Loader.vue';
import router from '@/router';
import { clickOutside } from '@/directives/click-outside';

import LazyLoadInstance from '@/utils/lazyload';

LazyLoadInstance.init();

gsap.registerPlugin(PixiPlugin);
gsap.registerPlugin(MotionPathPlugin);
gsap.defaults({ overwrite: 'auto' });

PixiPlugin.registerPIXI({
  DisplayObject,
  VERSION: '6.0.0',
  filters
});

import '@/style/site.scss';

const app = createApp(App).use(router);

// Global components
app.component('Icon', Icon);
app.component('OptimizedImage', OptimizedImage);
app.component('TaggedText', TaggedText);
app.component('TaggedMarkdown', TaggedMarkdown);
app.component('Markdown', Markdown);
app.component('Loader', Loader);

// Directives
app.directive('click-outside', clickOutside);

app.mount('#app');

(async function loadPolyfills() {
  if (!('scrollBehavior' in document.documentElement.style)) {
    // @ts-ignore
    const smoothscroll = await import(/* webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill');
    smoothscroll.polyfill();
  }
})();
