import { computed, ref } from 'vue';

const width = ref(window.innerWidth);
const height = ref(window.innerHeight);

export function useScreen() {
  window.addEventListener('resize', onResize);

  function onResize() {
    if (width.value !== window.innerWidth) {
      height.value = window.innerHeight;
    }

    width.value = window.innerWidth;
  }

  const widthPx = computed(() => `${width.value}px`);
  const heightPx = computed(() => `${height.value}px`);

  function widthPercentagePx(percentage: number) {
    return `${width.value * percentage}px`;
  }

  function heightPercentagePx(percentage: number) {
    return `${height.value * percentage}px`;
  }

  return {
    width,
    height,
    widthPx,
    heightPx,
    widthPercentagePx,
    heightPercentagePx
  };
}
