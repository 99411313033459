class ViewHeightHelper {
  windowWidth = 0;

  constructor() {
    window.addEventListener('resize', this.setViewportCssVariable.bind(this), false);

    this.setViewportCssVariable();
  }

  destroy() {
    window.removeEventListener('resize', this.setViewportCssVariable.bind(this), false);
  }

  setViewportCssVariable() {
    const vh = window.innerHeight / 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (this.windowWidth !== window.innerWidth) {
      this.windowWidth = window.innerWidth;
      document.documentElement.style.setProperty('--init-vh', `${vh}px`);
    }
  }
}

export default new ViewHeightHelper();
