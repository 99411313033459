
import { computed, defineComponent, PropType, ref, watch } from 'vue';

import { getPeriodLabel } from '@/components/Timeline/timeline';
import { TimelinePeriod } from '@functions/model/timeline';

export default defineComponent({
  props: {
    start: {
      type: String
    },
    end: {
      type: String
    },
    periods: {
      type: Array as PropType<TimelinePeriod[]>,
      required: true
    },
    currentPeriodIndex: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const localPeriodIndex = ref(0);

    const min = computed(() => 0);
    const max = computed(() => props.periods.length - 1);
    const waypoints = computed(() => props.periods.length - 2);

    function getSliderPositionByIndex(index: number) {
      // Some calculations to account for the fact that the input range slider knob is not centered in the far left and right,
      // causing regular percentage offsets to not represent the knob's position correctly.
      // Reference: https://css-tricks.com/value-bubbles-for-range-inputs/
      const knobSize = 25;
      const newValue = Number(((index - min.value) * 100) / (max.value - min.value));
      const newPosition = knobSize / 2 - (newValue * knobSize) / 100;

      return { left: `calc(${newValue}% + ${newPosition}px)` };
    }

    function onInput(e: InputEvent) {
      localPeriodIndex.value = parseInt((e.target as HTMLInputElement).value);
    }

    watch(
      () => props.currentPeriodIndex,
      index => (localPeriodIndex.value = index)
    );

    return {
      localPeriodIndex,
      min,
      max,
      waypoints,
      getSliderPositionByIndex,
      getPeriodLabel,
      onInput
    };
  }
});
