
import { computed, defineComponent, PropType } from 'vue';
import gsap, { Power1 } from 'gsap';

import { TimelineItem as ITimelineItem } from '@functions/model/timeline';
import { getPeriodLabel } from '@/components/Timeline/timeline';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<ITimelineItem>,
      required: true
    }
  },
  setup(props, context) {
    let tween: GSAPTween | undefined;

    const url = computed(() => `/thema?tag=${props.item.id}`);

    function onMouseEnter() {
      const element = document.querySelector(`#${context.attrs.id}`);

      if (!element || !(element instanceof HTMLAnchorElement) || parseFloat(element.style.opacity) < 0.1) {
        return;
      }

      tween = gsap.to(element, {
        overwrite: false,
        opacity: '+=1',
        scale: '+=0.05',
        duration: 0.3,
        ease: Power1.easeOut
      });
    }

    function onMouseLeave() {
      tween?.reverse().eventCallback('onComplete', () => {
        tween?.invalidate();
      });
    }

    return {
      url,
      onMouseEnter,
      onMouseLeave,
      getPeriodLabel
    };
  }
});
