
import { defineComponent, watch, ref } from 'vue';
import _debounce from 'lodash/debounce';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const loadingAfterDebounce = ref(false);

    const setLoading = () => {
      loadingAfterDebounce.value = true;
    };

    const debouncedLoading = _debounce(setLoading, 300);

    watch(
      () => props.loading,
      loading => {
        if (loading) {
          debouncedLoading();
        } else {
          debouncedLoading?.cancel();

          if (loadingAfterDebounce.value) {
            loadingAfterDebounce.value = false;
          }
        }
      },
      { immediate: true }
    );

    return {
      loadingAfterDebounce
    };
  }
});
