import * as PIXI from '@/lib/pixi';
import { Application, Container } from '@/lib/pixi';
import { TweenMax, Power1 } from 'gsap';

import { Melati } from '@/canvas/melati';
import { Vector } from '@/utils/vector';

const MELATI_AMOUNT = 6;
const MELATI_STEP_DIVIDER = 7;
const MELATI_BASE_SCALE = 1;
const MELATI_OUTLINE_ANIM_DURATION = 9;
const MELATI_BG_ANIM_DURATION = 5;

class MelatiController {
  public readonly container: Container;

  private readonly app: Application;
  private readonly outerMelati: Melati;
  private readonly backgroundMelati: Melati;
  private melatiOutlines: Melati[] = [];

  constructor(app: Application) {
    this.app = app;
    this.container = new PIXI.Container();

    this.outerMelati = this.createMelati();
    this.backgroundMelati = this.createMelati(MELATI_BASE_SCALE, true);
    this.backgroundMelati.graphics.angle = -1.5;

    this.melatiOutlines.push(this.outerMelati);

    this.spawn();
  }

  public getSpawnPoints(amount: number): Vector[] {
    const { leaves } = this.outerMelati;
    const pointsPerLeaf = Math.floor(amount / leaves.length);
    const sparePoints = amount % leaves.length;

    const spawnPoints: Vector[] = [];

    for (let i = 0; i < leaves.length; i++) {
      for (let j = 0; j < pointsPerLeaf; j++) {
        spawnPoints.push(leaves[i].getRandomPoint());
      }
    }

    for (let i = 0; i < sparePoints; i++) {
      spawnPoints.push(leaves[i].getRandomPoint());
    }

    return spawnPoints;
  }

  private spawn() {
    for (let i = 0; i < MELATI_AMOUNT - 1; i++) {
      const melati = this.createMelati(MELATI_BASE_SCALE - (MELATI_BASE_SCALE / MELATI_STEP_DIVIDER) * (i + 1));
      this.melatiOutlines.push(melati);
    }
  }

  public animate() {
    TweenMax.to(this.backgroundMelati.graphics, MELATI_BG_ANIM_DURATION, {
      rotation: -0.04,
      repeat: -1,
      yoyo: true,
      ease: Power1.easeInOut
    });
    for (const outline of this.melatiOutlines) {
      TweenMax.to(outline.graphics, MELATI_OUTLINE_ANIM_DURATION, {
        rotation: 0.02,
        repeat: -1,
        yoyo: true,
        ease: Power1.easeInOut
      });
    }
  }

  private createMelati(scale = MELATI_BASE_SCALE, fill = false) {
    const melati = new Melati(scale, fill);
    this.container.addChild(melati.graphics);

    return melati;
  }
}

export { MelatiController };
