import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cc5ad23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-block" }
const _hoisted_2 = {
  key: 0,
  class: "image-container left"
}
const _hoisted_3 = {
  key: 1,
  class: "image-container right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptimizedImage = _resolveComponent("OptimizedImage")!
  const _component_TaggedMarkdown = _resolveComponent("TaggedMarkdown")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.content.imagePositioning === 'links' && _ctx.content.image)
      ? (_openBlock(), _createElementBlock("figure", _hoisted_2, [
          _createVNode(_component_OptimizedImage, {
            class: "text-image left",
            width: 500,
            image: _ctx.content.image
          }, null, 8, ["image"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_TaggedMarkdown, {
      text: _ctx.content.text,
      tags: _ctx.tags
    }, null, 8, ["text", "tags"]),
    (_ctx.content.imagePositioning !== 'links' && _ctx.content.image)
      ? (_openBlock(), _createElementBlock("figure", _hoisted_3, [
          _createVNode(_component_OptimizedImage, {
            class: "text-image",
            width: 500,
            image: _ctx.content.image
          }, null, 8, ["image"])
        ]))
      : _createCommentVNode("", true)
  ]))
}