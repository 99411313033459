/* eslint-disable */
import LazyLoad from 'vanilla-lazyload';

class LazyLoadInstance {
  lazyload: any;
  init() {
    this.lazyload = new LazyLoad();
  }
}

export default new LazyLoadInstance();
