import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bdf0fff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "melati-flow" }
const _hoisted_2 = { class: "background-circle" }
const _hoisted_3 = {
  ref: "circle",
  class: "circle"
}
const _hoisted_4 = { class: "top" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "heading" }
const _hoisted_7 = {
  key: 0,
  class: "bottom"
}
const _hoisted_8 = { class: "counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_StepOne = _resolveComponent("StepOne")!
  const _component_MelatiStep = _resolveComponent("MelatiStep")!
  const _component_StepTwo = _resolveComponent("StepTwo")!
  const _component_StepThree = _resolveComponent("StepThree")!
  const _component_StepFour = _resolveComponent("StepFour")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", {
          class: _normalizeClass({ 'bigger-text': _ctx.currentStep === 4 })
        }, "Plaats je melati", 2),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.pageText), 1)
      ]),
      _createElementVNode("button", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args))),
        "aria-label": "Sluit nieuwe melati plaatsen"
      }, [
        _createVNode(_component_Icon, { name: "close" })
      ])
    ]),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.melatiData && !_ctx.submitted)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "steps",
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitMelati && _ctx.submitMelati(...args)), ["prevent"])),
              onKeydown: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args)))
            }, [
              _createVNode(_component_MelatiStep, {
                onNext: _ctx.nextStep,
                "current-step": _ctx.currentStep,
                show: _ctx.currentStep < 3,
                stepNumber: 1,
                disableButton: !_ctx.melatiData.message,
                "characters-used": _ctx.charactersUsed
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StepOne, {
                    modelValue: _ctx.melatiData,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.melatiData) = $event)),
                    disable: _ctx.currentStep != 1 && _ctx.currentStep != 4,
                    active: _ctx.currentStep === 1
                  }, null, 8, ["modelValue", "disable", "active"])
                ]),
                _: 1
              }, 8, ["onNext", "current-step", "show", "disableButton", "characters-used"]),
              _createVNode(_component_MelatiStep, {
                onNext: _ctx.nextStep,
                onPrevious: _ctx.previousStep,
                "current-step": _ctx.currentStep,
                show: _ctx.currentStep === 2 || _ctx.currentStep === 3,
                stepNumber: 2
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StepTwo, {
                    modelValue: _ctx.melatiData,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.melatiData) = $event)),
                    disable: _ctx.currentStep != 2 && _ctx.currentStep != 4,
                    active: _ctx.currentStep === 2
                  }, null, 8, ["modelValue", "disable", "active"])
                ]),
                _: 1
              }, 8, ["onNext", "onPrevious", "current-step", "show"]),
              _createVNode(_component_MelatiStep, {
                onNext: _ctx.nextStep,
                onPrevious: _ctx.previousStep,
                "current-step": _ctx.currentStep,
                show: _ctx.currentStep === 3,
                stepNumber: 3,
                disableButton: _ctx.invalidEmail
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StepThree, {
                    modelValue: _ctx.melatiData,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.melatiData) = $event)),
                    disable: _ctx.currentStep != 3 && _ctx.currentStep != 4,
                    active: _ctx.currentStep === 3
                  }, null, 8, ["modelValue", "disable", "active"])
                ]),
                _: 1
              }, 8, ["onNext", "onPrevious", "current-step", "show", "disableButton"]),
              _createVNode(_component_MelatiStep, {
                onNext: _ctx.nextStep,
                onPrevious: _ctx.previousStep,
                "current-step": _ctx.currentStep,
                submitted: _ctx.submitted,
                show: _ctx.currentStep === 4 && !_ctx.submitted,
                stepNumber: 4,
                melati: _ctx.melatiData
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_StepFour, { "melati-data": _ctx.melatiData }, null, 8, ["melati-data"])
                ]),
                _: 1
              }, 8, ["onNext", "onPrevious", "current-step", "submitted", "show", "melati"])
            ], 32))
          : (_ctx.submitted && !_ctx.error)
            ? (_openBlock(), _createBlock(_component_Loader, {
                key: 1,
                light: ""
              }))
            : (_openBlock(), _createElementBlock("p", {
                key: 2,
                class: _normalizeClass(["error", { critical: _ctx.submitted }])
              }, _toDisplayString(_ctx.errorMessage), 3))
      ]),
      _: 1
    }),
    (!_ctx.error || (_ctx.error && !_ctx.submitted))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.currentStep) + "/5", 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}