
import { MessageIndex } from '@functions/model/firestore';
import { defineComponent, PropType } from 'vue';
import TaggedText from '@/components/TaggedText.vue';
import { useScreen } from '@/store/useScreen';
import { useMeta } from '@/store/useMeta';
import { getIndexRoutePath } from '@/router';

export default defineComponent({
  components: { TaggedText },
  props: {
    message: {
      type: Object as PropType<MessageIndex>,
      required: true
    },
    initOpen: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    if (props.first) {
      useMeta({
        title: props.message.title,
        canonical: `/${getIndexRoutePath(props.message.type)}/${props.message.id}`
      });
    }

    const { heightPercentagePx } = useScreen();

    return {
      heightPercentagePx
    };
  }
});
