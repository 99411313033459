import { ref } from 'vue';

const canvasClicked = ref(false);

export function useCanvasClicked() {
  function setCanvasClicked(value: boolean) {
    canvasClicked.value = value;
  }

  return {
    canvasClicked,
    setCanvasClicked
  };
}
