import { ref } from 'vue';
import { RouteName } from '@/router';

const currentRouteName = ref('');
const currentRoutePath = ref('');

export function useCustomRoute() {
  function setCurrentRouteName(routeName: string) {
    currentRouteName.value = routeName;
  }

  function setCurrentRoutePath(routePath: string) {
    currentRoutePath.value = routePath;
  }

  function includesRoute(routeNames: RouteName[]) {
    return routeNames.includes(currentRouteName.value as RouteName);
  }

  return {
    currentRouteName,
    currentRoutePath,
    setCurrentRouteName,
    setCurrentRoutePath,
    includesRoute
  };
}
