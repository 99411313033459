import * as PIXI from '@/lib/pixi';
import { Application, Container } from '@/lib/pixi';
import { TweenMax } from 'gsap';

import { Point } from '@/canvas/point';
import { Vector } from '@/utils/vector';
import { MelatiController } from '@/canvas/melati-controller';
import { Index } from '@functions/model/firestore';
import { Canvas } from '@/canvas/canvas';
import { emitter, ShowHintEvent, Event } from '@/utils/events';
import { useOverviewIndexes } from '@/store/useOverviewIndexes';

const SPAWN_Y_OFFSET = 100;
const MAX_FLY_AMOUNT = 20;
const FLY_SPAWN_OFFSET = 100;

class PointController {
  private readonly app: Application;
  private readonly canvas: Canvas;
  private readonly melatiController: MelatiController;
  private readonly data: Index[];
  private time = 0;
  private flyPoints: number[] = [];

  public readonly container: Container;
  public points: Point[] = [];
  public visiblePoints: Point[] = [];
  public newMelati: boolean;

  constructor(app: Application, canvas: Canvas, melatiController: MelatiController, data: Index[], newMelati: boolean) {
    this.app = app;
    this.canvas = canvas;
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;

    // Makes the container interactive so a11y users can tab-select it
    // Pressing enter while selected enables/disables the points to be tabbed onto
    this.container.interactive = true;
    this.container.accessible = true;
    this.container.accessibleTitle =
      'Je kan zo door alle herinneringen op de melati heen met de tab toets, gebruik de Home en End toetsen om naar de eerste en laatste te springen.';

    document.addEventListener('focusin', () => {
      emitter.emit<ShowHintEvent>(Event.ShowHintEvent, {
        show: document.activeElement === this.container._accessibleDiv
      });
    });
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      // check if we're focused on a melati point, do nothing otherwise.
      if (document.activeElement?.parentElement === this.points[0].graphics._accessibleDiv?.parentElement) {
        if (event.key === 'End') {
          const point = this.points[0].graphics._accessibleDiv;
          if (point && point.parentElement && point.parentElement.lastElementChild) {
            (point.parentElement.lastElementChild as HTMLElement).focus();
          }
        } else if (event.key === 'Home') {
          const point = this.points[0].graphics._accessibleDiv;
          if (point && point.parentElement && point.parentElement.firstElementChild) {
            (point.parentElement.firstElementChild as HTMLElement).focus();
          }
        }
      }
    });

    this.melatiController = melatiController;
    this.data = data;
    this.newMelati = newMelati;
  }

  public spawn() {
    const { spawnPoints, setSpawnPoints } = useOverviewIndexes();
    if (!spawnPoints.value.length) {
      setSpawnPoints(this.melatiController.getSpawnPoints(this.data.length));
    }

    this.flyPoints = Array.from(Array(MAX_FLY_AMOUNT)).map((val, index) =>
      Math.floor(index * (this.data.length / MAX_FLY_AMOUNT))
    );

    for (let i = 0; i < this.data.length; i++) {
      if (!spawnPoints.value[i]) {
        this.melatiController.getSpawnPoints(1);
      }
      const flyIndex = this.flyPoints.indexOf(i);
      const fly = flyIndex > -1 || (this.data[i].isPersonal && this.newMelati);
      const pos = spawnPoints.value[i] ? spawnPoints.value[i] : this.melatiController.getSpawnPoints(1)[0];
      const spawnPos =
        this.data[i].isPersonal && this.newMelati
          ? new Vector(0, 0)
          : new Vector(
              (1 / this.canvas.container.scale.x) * (-window.innerWidth * 1.5),
              (1 / this.canvas.container.scale.x) * (window.innerHeight + SPAWN_Y_OFFSET)
            );
      const point = new Point(this.data[i], spawnPos, pos, this.newMelati, fly);

      this.container.addChild(point.graphics);
      this.points.push(point);
    }

    this.visiblePoints = this.points;
  }

  public animate(instant = false) {
    for (let i = 0; i < this.points.length; i++) {
      const flyIndex = this.flyPoints.indexOf(i);
      const fly = flyIndex > -1 || (this.points[i].data.isPersonal && this.newMelati);
      const timeout = fly ? flyIndex * FLY_SPAWN_OFFSET : 3000 + Math.min(this.data.length, MAX_FLY_AMOUNT) + i * 5;

      const durationZero =
        (instant && !this.data[i].isPersonal) || (instant && !this.newMelati && this.data[i].isPersonal);

      setTimeout(
        () => {
          this.points[i].animate(durationZero);
        },
        durationZero ? 0 : timeout
      );
    }

    if (!instant) {
      this.blurIn();
    }
  }

  public getClosestPointIndex(position: Vector): number {
    const closestPoint = this.visiblePoints.reduce((a, b) => {
      const pa = this.pointToScreenPoint(a.targetPos);
      const pb = this.pointToScreenPoint(b.targetPos);
      return Vector.distance(pa, position) < Vector.distance(pb, position) ? a : b;
    });

    return this.visiblePoints.indexOf(closestPoint);
  }

  public onTick(delta: number) {
    this.time += delta;

    // Points idle animation
    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];

      if (point.selected || !point.visible) {
        continue;
      }

      if (point.spawned) {
        const pos = new Vector(
          point.graphics.x + point.velocity * Math.cos((point.direction * (this.time + point.offset)) / 100),
          point.graphics.y + point.velocity * Math.sin((point.direction * (this.time + point.offset)) / 100)
        );
        point.update(pos);
      }
    }
  }

  private pointToScreenPoint(position: Vector): Vector {
    return new Vector(
      window.innerWidth / 2 + position.x * this.canvas.scale,
      window.innerHeight / 2 + position.y * this.canvas.scale
    );
  }

  private blurIn() {
    TweenMax.fromTo(
      this.container,
      3,
      {
        pixi: {
          blur: 20
        }
      },
      {
        pixi: {
          blur: 0
        }
      }
    ).eventCallback('onComplete', () => {
      this.container.filters = [];
    });
  }

  private toggleA11yOnPoints() {
    for (const point of this.points) {
      point.graphics.accessible = !point.graphics.accessible;
    }
  }
}

export { PointController };
