import { ref } from 'vue';
import { Timeline } from '@functions/model/timeline';
import { contentfulService } from '@/services';

const timeline = ref<Timeline | null>(null);

export function useTimeline() {
  function setTimeline(newTimeline: Timeline) {
    timeline.value = newTimeline;
  }

  async function getTimeline() {
    const data = await contentfulService.getTimeline();

    if (data) {
      setTimeline(data);
    }
  }

  return {
    timeline,
    setTimeline,
    getTimeline
  };
}
