import { ref, watch } from 'vue';
import { useCustomRoute } from '@/store/useCustomRoute';

interface MetaConfig {
  title?: string;
  titleSuffix?: string;
  description?: string;
  canonical?: string;
  noIndex?: boolean;
}

const descriptionEl = document.querySelector('meta[name="description"]');
const initialMetaConfig = {
  title: document.title,
  titleSuffix: 'Indisch Monument',
  description: descriptionEl?.getAttribute('content') || undefined
};

const config = ref<MetaConfig>(initialMetaConfig);
const { currentRoutePath } = useCustomRoute();

function clearInitialMeta() {
  document.title = '';
  if (descriptionEl) {
    descriptionEl.parentNode?.removeChild(descriptionEl);
  }
}

clearInitialMeta();

export function useMeta(newConfig?: MetaConfig) {
  function setMeta(newConfig: MetaConfig) {
    config.value = { ...initialMetaConfig, ...newConfig };
  }

  if (newConfig) {
    setMeta(newConfig);
  }

  return {
    config,
    initialMetaConfig
  };
}

watch(
  () => currentRoutePath.value,
  () => {
    config.value = initialMetaConfig;
  }
);
