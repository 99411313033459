import * as PIXI from '@/lib/pixi';
import { Graphics } from '@/lib/pixi';

import { MelatiLeaf } from '@/canvas/melati-leaf';
import { TweenMax } from 'gsap';

const LEAVES_AMOUNT = 6;
const LEAVES_START_ANGLE = 0;

class Melati {
  public leaves: MelatiLeaf[] = [];
  public graphics: Graphics;

  private readonly scale: number;
  private readonly fill: boolean;

  constructor(scale: number, fill = false) {
    this.scale = scale;
    this.fill = fill;

    this.graphics = new PIXI.Graphics();
    this.graphics.alpha = 0;

    let angle = LEAVES_START_ANGLE;
    for (let i = 0; i < LEAVES_AMOUNT; i++) {
      const leaf = new MelatiLeaf(angle, this.scale, this.fill);
      this.leaves.push(leaf);
      this.graphics.addChild(leaf.graphics);

      angle += 360 / LEAVES_AMOUNT;
    }

    this.fadeIn();
  }

  private fadeIn() {
    TweenMax.to(this.graphics, 3, { alpha: 1, ease: 'power4.out' });
  }
}

export { Melati };
