import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5167bdd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timeline-period" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimelineItem = _resolveComponent("TimelineItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.period.content, (item) => {
      return (_openBlock(), _createBlock(_component_TimelineItem, {
        key: _ctx.period.id + '-' + item.id,
        id: 'item-' + _ctx.period.id + '-' + item.id,
        item: item
      }, null, 8, ["id", "item"]))
    }), 128)),
    _createElementVNode("div", {
      class: "timeline-period-content",
      id: 'period-' + _ctx.period.id
    }, [
      (_ctx.period.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.period.title === _ctx.period.start.toString() ? '' : _ctx.period.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h1", null, _toDisplayString(_ctx.getPeriodLabel(_ctx.period)), 1)
    ], 8, _hoisted_2)
  ]))
}