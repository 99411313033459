import * as PIXI from '@/lib/pixi';
import { Application } from '@/lib/pixi';
import _debounce from 'lodash/debounce';

import { MelatiController } from '@/canvas/melati-controller';
import { PointController } from '@/canvas/point-controller';
import { Vector } from '@/utils/vector';
import { Container } from '@pixi/display';
import { Index } from '@functions/model/firestore';
import { watch } from 'vue';
import { useScreen } from '@/store/useScreen';

const WIDTH = 900;
const HEIGHT = 900;
const SCALE_OFFSET = 0.1;

class Canvas {
  public readonly app: Application;
  public readonly container: Container;
  public readonly melatiController: MelatiController;
  public pointController: PointController | null = null;
  public scale = 1;

  private data: Index[] = [];
  private spawned = false;
  private debouncedResize = _debounce(() => this.resize(), 300);

  public constructor(canvas: HTMLDivElement) {
    this.app = new PIXI.Application({
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundAlpha: 0,
      antialias: true,
      resizeTo: window
    });
    this.container = new PIXI.Container();
    this.container.position.set(window.innerWidth / 2, window.innerHeight / 2);
    this.container.sortableChildren = true;

    canvas.appendChild(this.app.view);

    this.scale = Math.min(1, Math.min(window.innerWidth / WIDTH, window.innerHeight / HEIGHT));
    this.melatiController = new MelatiController(this.app);

    this.container.addChild(this.melatiController.container);
    this.app.stage.addChild(this.container);
    this.app.ticker.add(this.onTick.bind(this));

    this.resize();

    const { width, height } = useScreen();
    watch(() => height.value, this.debouncedResize);
    watch(() => width.value, this.debouncedResize);
  }

  public createPoints(data: Index[], newMelati: boolean) {
    this.data = data;
    this.pointController = new PointController(this.app, this, this.melatiController, this.data, newMelati);
    this.container.addChild(this.pointController.container);
    this.pointController.spawn();
  }

  public animate(instant = false) {
    if (this.spawned) {
      return;
    }

    this.pointController?.animate(instant);
    this.melatiController.animate();

    this.spawned = true;
  }

  public destroy() {
    window.removeEventListener('resize', this.debouncedResize);
    this.app.destroy(true);
  }

  private onTick(delta: number) {
    this.pointController?.onTick(delta);
  }

  private resize() {
    this.scale = Math.min(
      1,
      Math.min(window.innerWidth / WIDTH - SCALE_OFFSET, window.innerHeight / HEIGHT - SCALE_OFFSET)
    );
    this.updateContainers();
  }

  private updateContainers() {
    const center = new Vector(window.innerWidth / 2, window.innerHeight / 2);

    this.container.position.set(center.x, center.y);
    this.container.scale.set(this.scale);
  }
}

export { Canvas };
