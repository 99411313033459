
import { Melati } from '@/model/data';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    melatiData: {
      type: Object as PropType<Melati>,
      required: true
    }
  }
});
