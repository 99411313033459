import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "content"]
const _hoisted_2 = {
  key: 0,
  name: "robots",
  content: "noindex"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.config.title)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "head title"
        }, [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metaTags, (metaItem, i) => {
        return (_openBlock(), _createElementBlock("meta", {
          name: metaItem.name,
          content: metaItem.content,
          key: i
        }, null, 8, _hoisted_1))
      }), 128)),
      (!_ctx.isProduction || _ctx.config.noIndex)
        ? (_openBlock(), _createElementBlock("meta", _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.canonical)
        ? (_openBlock(), _createElementBlock("link", {
            key: 1,
            rel: "canonical",
            href: _ctx.canonical
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}