import { ref } from 'vue';
import firebase from 'firebase/app';

const userId = ref('');

let auth: firebase.auth.Auth | undefined;

export function useAuth(initCallback?: (auth: firebase.auth.Auth) => void) {
  async function initAuth() {
    if (!auth) {
      await import(/* webpackChunkName: "firebase-auth" */ 'firebase/auth');
      auth = firebase.auth();
      initCallback?.(auth);
    }
  }

  async function signIn() {
    const userCredential = await auth?.signInAnonymously();

    if (userCredential?.user?.uid) {
      userId.value = userCredential.user.uid;
    }
  }

  async function signInAdmin(email: string, password: string): Promise<firebase.User | null> {
    return (await auth?.signInWithEmailAndPassword(email, password))?.user || null;
  }

  async function signOut(): Promise<void> {
    return auth?.signOut();
  }

  return {
    auth,
    userId,
    initAuth,
    signIn,
    signInAdmin,
    signOut
  };
}
