
import A11yDialog from 'a11y-dialog';
import { defineComponent, nextTick, onMounted, ref } from 'vue';

import MenuAboutLinks from '@/components/MenuAboutLinks.vue';
import TagsLinkList from '@/components/Tags/TagsLinkList.vue';
import { useHome } from '@/store/useHome';

export default defineComponent({
  components: {
    MenuAboutLinks,
    TagsLinkList
  },
  setup() {
    const { home, getHome } = useHome();

    const container = ref<HTMLElement | null>(null);
    const dialog = ref<A11yDialog | null>(null);

    const show = ref(false);

    const app = ref<HTMLElement | null>(null);

    onMounted(async () => {
      if (!home.value) {
        await getHome();
      }

      if (container.value) {
        app.value = document.getElementById('app');
        if (!app.value) return;
        dialog.value = new A11yDialog(container.value);
        dialog.value.on('show', async () => {
          app.value?.setAttribute('aria-hidden', 'true');
          show.value = true;
        });
        dialog.value.on('hide', () => {
          app.value?.setAttribute('aria-hidden', 'false');
          show.value = false;
        });
      }
    });

    const toggleContent = async () => {
      show.value = !show.value;

      await nextTick();

      if (show.value) {
        dialog.value?.show();
      } else {
        dialog.value?.hide();
      }
    };

    const setFocus = async () => {
      container.value?.focus();
    };

    return {
      home,
      toggleContent,
      container,
      show,
      setFocus
    };
  }
});
