
import { defineComponent, nextTick, PropType, ref } from 'vue';

import ContentDetail from '@/components/Content/ContentDetail.vue';
import TextBlock from '@/components/Content/TextBlock.vue';

import { TagIndex } from '@functions/model/firestore';
import { contentfulService } from '@/services';
import { TagResponse } from '@functions/model/content';
import { useMeta } from '@/store/useMeta';
import { getIndexRoutePath } from '@/router';

export default defineComponent({
  components: {
    ContentDetail,
    TextBlock
  },
  emits: ['open-detail'],
  props: {
    tag: {
      type: Object as PropType<TagIndex>,
      required: true
    },
    initOpen: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    if (props.first) {
      useMeta({
        title: props.tag.metaTitle || props.tag.title,
        description: props.tag.metaDescription || props.tag.introText,
        canonical: `/${getIndexRoutePath(props.tag.type)}/${props.tag.id}`
      });
    }

    const fullTag = ref<TagResponse | null>(null);
    const loading = ref(true);

    const onOpenContent = async () => {
      fullTag.value = await contentfulService.getSubTagById(props.tag.id);
      loading.value = false;

      await nextTick();

      emit('open-detail');
    };

    return {
      fullTag,
      onOpenContent,
      loading
    };
  }
});
