import { ref } from 'vue';
import { contentfulService } from '@/services';
import { HomeResponse } from '@functions/model/content';

const home = ref<HomeResponse | null>(null);

let homePromise: Promise<HomeResponse | null> | undefined;

export function useHome() {
  function setHome(newHome: HomeResponse) {
    home.value = newHome;
  }

  async function getHome() {
    if (homePromise) {
      return;
    }

    homePromise = contentfulService.getHome();
    const data = await homePromise;

    if (data) {
      setHome(data);
    }
  }

  return {
    home,
    setHome,
    getHome
  };
}
