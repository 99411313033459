
import { defineComponent, onMounted, ref } from 'vue';

import Welcome from '@/components/Home/Welcome.vue';
import CanvasIntroduction from '@/components/Home/CanvasIntroduction.vue';
import Canvas from '@/components/Canvas/Canvas.vue';
import KeyboardNavHint from '@/components/KeyboardNavHint.vue';
import NewMelati from '@/components/Home/NewMelati.vue';

import { useHomePageState } from '@/store/useHomePageState';
import { useHome } from '@/store/useHome';
import { emitter, NewMelatiFinishedEvent, Event } from '@/utils/events';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Home',
  setup() {
    const { query } = useRoute();
    const { home, getHome } = useHome();
    const { isIntro, isQuotes, isCounter } = useHomePageState();
    const openOverlay = ref(false);
    const canvasReadyToReceiveEvents = ref(true);

    emitter.on<NewMelatiFinishedEvent>(Event.NewMelatiFinished, onNewMelatiFinished);

    onMounted(() => {
      if (query.melatiId) {
        canvasReadyToReceiveEvents.value = false;
      }

      if (!home.value) {
        getHome();
      }
    });

    function onNewMelatiFinished(event?: NewMelatiFinishedEvent) {
      if (event?.data && query.melatiId) {
        canvasReadyToReceiveEvents.value = true;
        openOverlay.value = true;
        emitter.off<NewMelatiFinishedEvent>(Event.SelectPoint, onNewMelatiFinished);
      }
    }

    const close = () => {
      openOverlay.value = false;
    };

    return {
      isIntro,
      isQuotes,
      isCounter,
      openOverlay,
      home,
      close,
      canvasReadyToReceiveEvents
    };
  },
  components: {
    Welcome,
    Canvas,
    CanvasIntroduction,
    NewMelati,
    KeyboardNavHint
  }
});
