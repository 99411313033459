import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sitemap"
}
const _hoisted_2 = { class: "main-pages" }
const _hoisted_3 = { class: "sub-pages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.sitemap)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("header", null, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.sitemap.title), 1)
        ]),
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sitemap.items, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.id
            }, [
              _createVNode(_component_router_link, {
                to: item.mainPage.path
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.mainPage.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]),
              _createElementVNode("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subPages, (subPage) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: subPage.id
                  }, [
                    _createVNode(_component_router_link, {
                      to: subPage.path
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(subPage.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}