
import { computed, defineComponent, onMounted, ref } from 'vue';
import { MessageIndex } from '@functions/model/firestore';
import { useOverviewIndexes } from '@/store/useOverviewIndexes';

import A11yDialog from 'a11y-dialog';
import { logInteractionEvent } from '@/utils/analytics';

export default defineComponent({
  name: 'NewMelati',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const result = ref<MessageIndex | null>();
    const show = ref(true);
    const { personalMelati } = useOverviewIndexes();

    const container = ref<HTMLElement | null>(null);
    const dialog = ref<A11yDialog | null>(null);
    const app = ref<HTMLElement | null>(null);

    onMounted(async () => {
      result.value = personalMelati.value;
    });

    const close = () => {
      show.value = false;
      dialog.value?.hide();
    };

    const leave = async () => {
      if (!show.value) emit('close');
    };

    const setElements = async () => {
      if (container.value) {
        app.value = document.getElementById('app');
        if (!app.value) return;
        dialog.value = new A11yDialog(container.value);
        app.value?.setAttribute('aria-hidden', 'true');
        dialog.value.show();
        dialog.value.on('hide', () => {
          app.value?.setAttribute('aria-hidden', 'false');
          show.value = false;
        });
      }
      container.value?.focus();
    };

    const melatiUrl = computed(() => `${window.location.href}melati/${props.id}`);
    const melatiText = computed(() =>
      encodeURIComponent(
        `Ik heb een persoonlijk bericht achter gelaten op indischmonument.nl. Bekijk mijn melati ${melatiUrl.value} #indischmonument #ikherdenk`
      )
    );
    const facebookShare = computed(() => `https://www.facebook.com/sharer/sharer.php?u=${melatiUrl.value}`);

    const twitterShare = computed(() => {
      return `https://twitter.com/intent/tweet?text=${melatiText.value}`;
    });

    const whatsappShare = computed(() => {
      return `https://api.whatsapp.com/send?text=${melatiText.value}`;
    });

    const emailShare = computed(() => {
      return `mailto:?subject=Mijn melati&body=${melatiText.value}`;
    });

    const onShare = (platform: string) => {
      logInteractionEvent({
        page_type: 'melati',
        event_name: 'share',
        interaction_type: 'click',
        platform
      });
    };

    return {
      result,
      show,
      close,
      leave,
      facebookShare,
      twitterShare,
      whatsappShare,
      emailShare,
      setElements,
      onShare,
      container
    };
  }
});
