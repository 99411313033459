import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b87d70a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu-content-container",
  ref: "container",
  "aria-hidden": "true"
}
const _hoisted_2 = {
  key: 0,
  class: "menu-content",
  role: "document"
}
const _hoisted_3 = { class: "menu-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TagsLinkList = _resolveComponent("TagsLinkList")!
  const _component_MenuAboutLinks = _resolveComponent("MenuAboutLinks")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "menu-button",
      "aria-label": "Open menu",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleContent && _ctx.toggleContent(...args)), ["stop"]))
    }, [
      _createVNode(_component_Icon, { name: "menu" }),
      _createTextVNode(" Menu ")
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, {
        name: "open",
        onAfterEnter: _ctx.setFocus
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            (_ctx.show && _ctx.home?.hamburgerMenu)
              ? _withDirectives((_openBlock(), _createElementBlock("nav", _hoisted_2, [
                  _createElementVNode("button", {
                    class: "close-btn",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleContent && _ctx.toggleContent(...args))),
                    "aria-label": "Sluit menu"
                  }, [
                    _createVNode(_component_Icon, { name: "close" })
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_TagsLinkList, { onClose: _ctx.toggleContent }, null, 8, ["onClose"]),
                    _createVNode(_component_MenuAboutLinks, { onClick: _ctx.toggleContent }, null, 8, ["onClick"])
                  ])
                ])), [
                  [_directive_click_outside, { action: _ctx.toggleContent }]
                ])
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, _ctx.show]
          ])
        ]),
        _: 1
      }, 8, ["onAfterEnter"])
    ]))
  ], 64))
}