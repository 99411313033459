
import { defineComponent, onMounted, ref } from 'vue';
import { gsap } from 'gsap';

export default defineComponent({
  setup() {
    const circle = ref<HTMLElement | undefined>(undefined);
    const circleAnimation = ref<undefined | GSAPTimeline>(undefined);

    onMounted(() => {
      if (!circle.value) return;
      circleAnimation.value = gsap.timeline().to(circle.value, {
        x: 'random(-40, 200, 10)',
        y: 'random(-80, 50, 10)',
        duration: 10,
        ease: 'none',
        repeat: -1,
        repeatRefresh: true
      });
    });

    return {
      circle
    };
  }
});
