import * as PIXI from '@/lib/pixi';
import { Graphics } from '@/lib/pixi';

import { Vector, Triangle } from '@/utils/vector';
import { random } from '@/utils/math';

class MelatiLeaf {
  private readonly angle: number;
  private readonly scale: number;
  private readonly fill: boolean;

  private readonly pos1: Vector;
  private readonly pos2: Vector;
  private readonly pos3: Vector;

  private triangles: Triangle[] = [];

  public graphics: Graphics;

  constructor(angle: number, scale: number, fill = false) {
    this.angle = angle;
    this.scale = scale;
    this.fill = fill;

    this.graphics = new PIXI.Graphics();
    this.graphics.angle = this.angle;

    // Leaf polygon vectors
    this.pos1 = new Vector(0, -460);
    this.pos2 = new Vector(this.pos1.x + 180, this.pos1.y + 140);
    this.pos3 = new Vector(this.pos2.x - 5, this.pos2.y + 173);

    this.draw();
    this.calculateTriangles();
  }

  public draw() {
    this.graphics.clear();

    if (this.fill) {
      this.graphics.lineStyle(0);
      this.graphics.beginFill(0xffffff, 0.1);
    } else {
      this.graphics.lineStyle(1.5, 0xffffff, 0.2);
    }

    // Smoothly draw bezier curves between leaf polygon vectors to mimic the shape of a Melati leaf
    this.scaledBezierCurveTo(this.graphics, -30, -100, -75, -340, this.pos1.x, this.pos1.y);
    this.scaledBezierCurveTo(
      this.graphics,
      this.pos1.x + 80,
      this.pos1.y + -60,
      this.pos1.x + 150,
      this.pos1.y + 80,
      this.pos2.x,
      this.pos2.y
    );
    this.scaledBezierCurveTo(
      this.graphics,
      this.pos2.x + 10,
      this.pos2.y + 30,
      this.pos2.x + 15,
      this.pos2.y + 20,
      this.pos3.x,
      this.pos3.y
    );

    this.graphics.endFill();
    this.graphics.closePath();
  }

  public getRandomPoint(): Vector {
    return this.triangles[Math.floor(random(0, this.triangles.length))].getRandomVectorInside();
  }

  private calculateTriangles() {
    const pos = new Vector(0, 0);

    const pos1WithAngle = Vector.rotateByAngle(pos, this.pos1, this.angle);
    const pos2WithAngle = Vector.rotateByAngle(pos, this.pos2, this.angle);
    const pos3WithAngle = Vector.rotateByAngle(pos, this.pos3, this.angle);

    const leafTriangle1 = new Triangle(pos, pos1WithAngle, pos2WithAngle);
    const leafTriangle2 = new Triangle(pos, pos2WithAngle, pos3WithAngle);

    this.triangles.push(leafTriangle1, leafTriangle2);
  }

  private scaledBezierCurveTo(
    graphics: Graphics,
    cpx1: number,
    cpy1: number,
    cpx2: number,
    cpy2: number,
    x: number,
    y: number
  ) {
    graphics.bezierCurveTo(
      cpx1 * this.scale,
      cpy1 * this.scale,
      cpx2 * this.scale,
      cpy2 * this.scale,
      x * this.scale,
      y * this.scale
    );
  }
}

export { MelatiLeaf };
