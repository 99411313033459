
import { onMounted, onUnmounted, defineComponent, ref, PropType } from 'vue';
import Hammer from 'hammerjs';

import { CanvasTour } from '@/canvas/canvas-tour';
import { Canvas } from '@/canvas/canvas';
import { Vector } from '@/utils/vector';
import { useTour } from '@/store/useTour';
import { logInteractionEvent } from '@/utils/analytics';

export default defineComponent({
  props: {
    canvas: {
      type: Object as PropType<Canvas>,
      required: true
    },
    el: {
      type: Object as PropType<HTMLDivElement>,
      required: true
    }
  },
  setup(props, { emit }) {
    const canvasTour = ref<CanvasTour | null>(null);
    const showSwipeHint = ref<boolean>(true);
    const hammer = new Hammer(props.el, {
      inputClass: Hammer.TouchInput
    });

    const { tourActive } = useTour();

    hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });

    const onTap = (e: TouchInput) => {
      const index =
        canvasTour.value?.canvas.pointController?.getClosestPointIndex(new Vector(e.center.x, e.center.y)) || 0;
      canvasTour.value?.start(index);
      hammer.off('tap', onTap);
      emit('interacted');
    };

    const onSwipe = (e: TouchInput) => {
      showSwipeHint.value = false;
      e.direction === Hammer.DIRECTION_DOWN ? canvasTour.value?.previous() : canvasTour.value?.next();

      logInteractionEvent({
        page_type: 'home',
        event_name: 'swipe_melati_navigation_mobile',
        interaction_type: 'gesture',
        direction: Hammer.DIRECTION_DOWN ? 'down' : 'up'
      });
    };

    const onStart = () => {
      hammer.on('swipe', onSwipe);
    };

    const onStop = () => {
      hammer.off('swipe', onSwipe);
      hammer.on('tap', onTap);
    };

    const previous = () => {
      showSwipeHint.value = false;
      canvasTour.value?.previous();

      logInteractionEvent({
        page_type: 'home',
        event_name: 'previous_melati_navigation_mobile',
        interaction_type: 'click'
      });
    };

    const next = () => {
      showSwipeHint.value = false;
      canvasTour.value?.next();

      logInteractionEvent({
        page_type: 'home',
        event_name: 'next_melati_navigation_mobile',
        interaction_type: 'click'
      });
    };

    const stop = () => {
      canvasTour.value?.stop();

      logInteractionEvent({
        page_type: 'home',
        event_name: 'exit_melati_navigation_mobile',
        interaction_type: 'click'
      });
    };

    onMounted(() => {
      canvasTour.value = new CanvasTour(props.canvas);
      canvasTour.value.onStart = onStart;
      canvasTour.value.onStop = onStop;

      hammer.on('tap', onTap);
    });

    onUnmounted(() => {
      canvasTour.value = null;
      hammer.off('swipe', onSwipe);
      hammer.off('tap', onTap);
    });

    return {
      stop,
      next,
      previous,
      canvasTour,
      showSwipeHint,
      tourActive
    };
  }
});
