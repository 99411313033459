import { ref } from 'vue';
import { indexService } from '@/services';
import { Index, Message, MessageIndex, IndexType, StatisticsTotals } from '@functions/model/firestore';
import { Vector } from '@/utils/vector';

const indexes = ref<Index[] | null>(null);
const statisticsTotals = ref<StatisticsTotals | null>(null);
const personalMelati = ref<MessageIndex | null>(null);
const spawnPoints = ref<Vector[]>([]);

export function useOverviewIndexes() {
  function setIndexes(data: Index[]) {
    indexes.value = data;
  }

  function setStatisticsTotals(data: StatisticsTotals) {
    statisticsTotals.value = data;
  }

  async function getIndexes() {
    const { indexes, statisticsTotals } = await indexService.getOverviewData();

    const personalMelatiId = localStorage.getItem('personalMelatiId');
    if (personalMelatiId && indexes) {
      const found = indexes.find(el => el.id === personalMelatiId);
      if (found) {
        found.isPersonal = true;
      }
      if (!found) {
        if (personalMelati.value) {
          indexes.push(personalMelati.value);
        } else {
          const personalMelatiResponse = await indexService.getIndexById(personalMelatiId);
          if (personalMelatiResponse?.type === IndexType.Message) {
            personalMelatiResponse.isPersonal = true;
            indexes.push(personalMelatiResponse);
          }
        }
      }
    }

    setIndexes(indexes || []);
    setStatisticsTotals(statisticsTotals);
  }

  function addPersonalMelati(message: Message) {
    personalMelati.value = {
      id: message.id,
      from: message.from,
      to: message.to,
      title: message.title,
      created: message.created,
      updated: message.updated,
      text: message.text,
      tags: [],
      tagIds: [],
      type: IndexType.Message,
      isPersonal: true
    };

    if (indexes.value) {
      const previous = indexes.value.find(obj => Object.prototype.hasOwnProperty.call(obj, 'isPersonal'));
      if (previous) previous.isPersonal = false;
      indexes.value.push(personalMelati.value);
    }
  }

  function setSpawnPoints(newSpawnPoints: Vector[]) {
    spawnPoints.value = newSpawnPoints;
  }

  return {
    indexes,
    statisticsTotals,
    spawnPoints,
    personalMelati,
    setIndexes,
    getIndexes,
    addPersonalMelati,
    setSpawnPoints
  };
}
