class Vector {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public add(v: Vector) {
    this.x += v.x;
    this.y += v.y;

    return this;
  }

  static rotateByAngle(center: Vector, v: Vector, angle: number): Vector {
    const radians = (Math.PI / 180) * angle;

    const x = Math.cos(radians) * (v.x - center.x) - Math.sin(radians) * (v.y - center.y) + center.x;
    const y = Math.cos(radians) * (v.y - center.y) + Math.sin(radians) * (v.x - center.x) + center.y;

    return new Vector(x, y);
  }

  static distance(v1: Vector, v2: Vector): number {
    return Math.sqrt(Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2));
  }
}

class Triangle {
  a: Vector;
  b: Vector;
  c: Vector;

  constructor(a: Vector, b: Vector, c: Vector) {
    this.a = a;
    this.b = b;
    this.c = c;
  }

  public getRandomVectorInside(): Vector {
    // https://observablehq.com/@scarysize/finding-random-points-in-a-polygon
    let wb = Math.random();
    let wc = Math.random();

    if (wb + wc > 1) {
      wb = 1 - wb;
      wc = 1 - wc;
    }

    const randomBX = wb * (this.b.x - this.a.x);
    const randomBY = wb * (this.b.y - this.a.y);
    const randomCX = wc * (this.c.x - this.a.x);
    const randomCY = wc * (this.c.y - this.a.y);

    const randomX = randomBX + randomCX + this.a.x;
    const randomY = randomBY + randomCY + this.a.y;

    return new Vector(randomX, randomY);
  }
}

export { Vector, Triangle };
