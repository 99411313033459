
import { defineComponent, PropType, ref } from 'vue';
import { marked } from 'marked';

import { TagReference } from '@functions/model/firestore';
import { TextWithImage } from '@functions/model/content';

export default defineComponent({
  props: {
    content: {
      type: Object as PropType<TextWithImage>,
      required: true
    },
    tags: {
      type: Array as PropType<TagReference[]>,
      default: () => []
    }
  },
  setup() {
    const markdown = ref('');

    const parseMarkdown = (text: string) => {
      if (text) return (markdown.value = marked.parse(text));
    };

    return {
      markdown,
      parseMarkdown
    };
  }
});
