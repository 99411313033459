import mitt from 'mitt';

import { Index } from '@functions/model/firestore';
import { Point } from '@/canvas/point';

export enum Event {
  SelectPoint = 'selectPoint',
  ClickPoint = 'clickPoint',
  NewMelatiFinished = 'newMelatiFinished',
  ShowHintEvent = 'ShowHintEvent'
}

export type SelectPointEvent = { point: Point; first?: boolean } | null;
export type ClickPointEvent = { data: Index };
export type NewMelatiFinishedEvent = { data: boolean };
export type ShowHintEvent = { show: boolean };

export const emitter = mitt();
