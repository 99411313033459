import { ref } from 'vue';

const showCanvasHint = ref(true);

export function useHint() {
  function hideCanvasHint() {
    showCanvasHint.value = false;
  }

  return {
    showCanvasHint,
    hideCanvasHint
  };
}
