
import { defineComponent, PropType } from 'vue';

import { RelatedContentResponse } from '@functions/model/content';

export default defineComponent({
  props: {
    related: {
      type: Object as PropType<RelatedContentResponse>,
      required: true
    }
  }
});
