
import { defineComponent, computed } from 'vue';
import { marked } from 'marked';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const markdown = computed(() => marked.parse(props.text));

    return {
      markdown
    };
  }
});
