import { MelatiUnparsedMessage } from '@/model/data';

export const separateTitleFromText = (newMelatiData: MelatiUnparsedMessage) => {
  if (!newMelatiData.message) {
    return;
  }

  // Get first 100 characters without cutting off words
  const stripped = newMelatiData.message.match(/^(.{0,100}(?![\w.,!?:;]))/);
  if (!stripped) {
    return;
  }
  newMelatiData.title = stripped[0];

  // Check if title can be limited to all characters up until ./?/!
  const firstSentence = stripped[0].match(/^.*?[.!?](?=\s[A-Z]|\s?$)(?!.*\))/);
  if (firstSentence) {
    newMelatiData.title = firstSentence[0];
  }

  // Check if string can be cut off at a comma
  else {
    const endsWithComma = stripped[0].match(/^[^,]*/);
    if (endsWithComma) {
      newMelatiData.title = endsWithComma[0];
    }
  }

  // Subtract title from message to get the text
  newMelatiData.text = newMelatiData.message.split(newMelatiData.title).pop();
};
