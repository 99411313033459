
import { defineComponent, ref } from 'vue';
import { emitter, Event, ShowHintEvent } from '@/utils/events';

export default defineComponent({
  name: 'KeyboardNavHint',
  setup() {
    const showHint = ref(false);
    emitter.on<ShowHintEvent>(Event.ShowHintEvent, event => {
      if (event) {
        showHint.value = event.show;
      }
    });
    return { showHint };
  }
});
