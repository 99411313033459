
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import gsap from 'gsap';

import CanvasTour from '@/components/Canvas/CanvasTour.vue';
import CanvasSelectedContent from '@/components/Canvas/CanvasSelectedContent.vue';
import CanvasInteractionHint from '@/components/Canvas/CanvasInteractionHint.vue';

import { Canvas } from '@/canvas/canvas';
import { ClickPointEvent, emitter, Event, SelectPointEvent } from '@/utils/events';
import { Index, IndexType } from '@functions/model/firestore';
import { Vector } from '@/utils/vector';
import { useHomePageState } from '@/store/useHomePageState';
import { useOverviewIndexes } from '@/store/useOverviewIndexes';
import { useInterfaceSettings } from '@/store/useInterfaceSettings';
import { usePageTransition } from '@/store/usePageTransition';
import router, { getIndexRouteName } from '@/router';
import { useTour } from '@/store/useTour';
import { useCanvasClicked } from '@/store/useCanvasClicked';
import { useHint } from '@/store/useHint';
import { logInteractionEvent } from '@/utils/analytics';

export default defineComponent({
  props: {
    force: {
      type: Boolean
    },
    newMelati: {
      type: Boolean
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CanvasTour,
    CanvasSelectedContent,
    CanvasInteractionHint
  },
  setup(props) {
    const canvasContainer = ref<HTMLDivElement | null>(null);
    const canvas = ref<Canvas | null>(null);
    const selected = ref<Index | null>(null);
    const active = ref(false);
    const interactable = ref(false);
    const first = ref(false);
    const position = ref<Vector | null>(null);
    const visitedIndexes = ref<Index[]>([]);
    const openingContent = ref(false);

    const { homePageState, isCounter, isMelati } = useHomePageState();
    const { indexes, getIndexes } = useOverviewIndexes();
    const { setShowAllMenus } = useInterfaceSettings();
    const { registerPageLeaveTransition } = usePageTransition();
    const { tourActive } = useTour();
    const { setCanvasClicked } = useCanvasClicked();
    const { showCanvasHint, hideCanvasHint } = useHint();

    emitter.on<SelectPointEvent>(Event.SelectPoint, onSelectPoint);
    emitter.on<ClickPointEvent>(Event.ClickPoint, onClickPoint);

    const setActive = (instant = false) => {
      if (active.value) {
        return;
      }

      canvas.value?.animate(instant);
      active.value = true;

      instant ? (interactable.value = true) : setTimeout(() => (interactable.value = true), 6500);
    };

    onMounted(async () => {
      if (!canvasContainer.value) {
        return;
      }

      canvas.value = new Canvas(canvasContainer.value);

      if (!indexes.value) {
        await getIndexes();
      }

      if (indexes.value) {
        canvas.value?.createPoints(indexes.value, props.newMelati);
      }

      if (isMelati.value || props.force) {
        setActive(isMelati.value);
      }
    });

    onUnmounted(() => {
      emitter.off<SelectPointEvent>(Event.SelectPoint, onSelectPoint);
      emitter.off<ClickPointEvent>(Event.ClickPoint, onClickPoint);

      canvas.value?.destroy();
    });

    function onSelectPoint(event?: SelectPointEvent) {
      if (openingContent.value) {
        return;
      }

      hideCanvasHint();

      first.value = !!event?.first;
      selected.value = event?.point.data || null;
      setShowAllMenus(!selected.value);

      if (
        selected.value &&
        selected.value.type !== IndexType.Message &&
        !visitedIndexes.value.find(i => i.id === selected.value?.id)
      ) {
        visitedIndexes.value.push(selected.value);
      }

      if (event?.point) {
        position.value = new Vector(
          window.innerWidth / 2 + event.point.targetPos.x * (canvas.value?.container.scale.x || 1),
          window.innerHeight / 2 + event.point.targetPos.y * (canvas.value?.container.scale.y || 1)
        );
      }

      if (selected.value) {
        logInteractionEvent({
          page_type: 'home',
          interaction_type: 'hover',
          event_name: 'view_melati_point',
          point_type: selected.value.type,
          point_id: selected.value.id
        });
      }
    }

    function onClickPoint(event?: ClickPointEvent) {
      if (!event) {
        return;
      }

      setCanvasClicked(true);
      if (event.data.type !== IndexType.Message) {
        registerPageLeaveTransition(
          new Promise(resolve => {
            gsap
              .to('.selected-image', {
                duration: 0.5,
                height: '100%',
                width: '100%',
                transform: 'translate3d(-7px, -7px, 0)'
              })
              .eventCallback('onComplete', () => {
                setShowAllMenus(true);
                resolve();
              });
          })
        );
      } else {
        setShowAllMenus(true);
      }

      router.push({ name: getIndexRouteName(event.data.type), params: { id: event.data.id || '' } });
    }

    watch(
      () => homePageState.value,
      () => {
        if (isCounter.value) {
          setActive();
        }
      }
    );

    return {
      canvasContainer,
      canvas,
      selected,
      indexes,
      visitedIndexes,
      position,
      active,
      interactable,
      first,
      tourActive,
      showCanvasHint,
      hideCanvasHint
    };
  }
});
