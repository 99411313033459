import { computed, ref } from 'vue';

export type Environment = 'development' | 'acceptance' | 'production';

const env = ref<Environment>(process.env.VUE_APP_MODE as Environment);

export function useEnv() {
  const isDevelopment = computed(() => env.value === 'development');
  const isProduction = computed(() => env.value === 'production');

  return {
    env,
    isDevelopment,
    isProduction
  };
}
