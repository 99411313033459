import * as PIXI from '@/lib/pixi';
import { Graphics } from '@/lib/pixi';
import { TweenMax, Power4, Power2 } from 'gsap';

import { Vector } from '@/utils/vector';
import { random } from '@/utils/math';
import { emitter, Event, NewMelatiFinishedEvent, SelectPointEvent } from '@/utils/events';
import { Sprite } from '@pixi/sprite';
import { Index, IndexType } from '@functions/model/firestore';

const MIN_POINT_OPACITY = 0.5;
const MAX_POINT_OPACITY = 0.9;
const POINT_HIT_AREA = 150;
const SELECTED_TWEEN_TIME = 0.3;
const SELECTED_SIZE = 60;
const SELECTED_HIT_AREA = 100;
const SHOOT_INITIAL_SIZE = 600;
const SHOOT_DURATION = 2;
const FADE_DURATION = 2;

class Point {
  public selected = false;
  public visible = true;
  public spawned = false;
  public pos: Vector;
  public spawnPos: Vector;
  public targetPos: Vector;

  public readonly offset: number = random(0, 500);
  public readonly velocity: number = random(0.02, 0.05);
  public readonly direction: number = random(0, 1) > 0.5 ? -1 : 1;
  public readonly opacity: number;
  public readonly graphics: Sprite;
  public readonly data: Index;

  private readonly radius: number;
  private readonly fly: boolean;
  private readonly isPersonalMelati: boolean | undefined;
  private readonly newMelati: boolean;

  private outline: Graphics | null = null;

  constructor(data: Index, spawnPos: Vector, targetPos: Vector, newMelati: boolean, fly = false) {
    this.data = data;
    this.spawnPos = spawnPos;
    this.targetPos = targetPos;
    this.isPersonalMelati = data.isPersonal ? true : false;
    this.radius = this.getRadius();
    this.opacity = this.getRandomOpacity();
    this.fly = fly;

    this.newMelati = newMelati;

    this.pos = this.fly ? spawnPos : targetPos;

    this.graphics = PIXI.Sprite.from(
      require(this.isPersonalMelati ? '../assets/img/circle-yellow.png' : '../assets/img/circle.png')
    );
    this.graphics.anchor.set(0.5);
    // From https://github.com/scottmcdonnell/pixi-accessibility
    // The container in point-controller.ts enables/disables the accessible property
    this.graphics.accessibleTitle = this.data.title;
    this.graphics.accessible = true;

    this.graphics.zIndex = this.data.isPersonal ? 1 : 0;
    this.graphics.hitArea = new PIXI.Circle(0, 0, POINT_HIT_AREA);

    if (this.newMelati && this.isPersonalMelati) {
      this.graphics.width = window.innerWidth;
      this.graphics.height = window.innerWidth;
      this.graphics.alpha = 1;
    } else {
      this.graphics.width = this.fly ? SHOOT_INITIAL_SIZE : 0;
      this.graphics.height = this.fly ? SHOOT_INITIAL_SIZE : 0;
      this.graphics.alpha = this.fly ? this.opacity : 0;
    }

    this.graphics.on('mouseover', () => this.select());
    this.graphics.on('mouseout', () => this.deselect());
    this.graphics.on('click', () => emitter.emit(Event.ClickPoint, { data: this.data }));

    this.draw();
  }

  public animate(instant = false) {
    const overriddenDuration = instant ? 0 : undefined;

    const spawnAnimation = this.fly ? this.flyAnimation(overriddenDuration) : this.fadeAnimation(overriddenDuration);

    if (instant) {
      this.setInteractive();
      this.spawned = true;
    } else {
      spawnAnimation.eventCallback('onComplete', () => {
        this.setInteractive();
        this.spawned = true;
        if (this.isPersonalMelati && this.newMelati)
          emitter.emit<NewMelatiFinishedEvent>(Event.NewMelatiFinished, { data: true });
      });
    }
  }

  public update(pos: Vector) {
    this.pos = pos;

    this.draw();
  }

  public draw() {
    this.graphics.position.set(this.pos.x, this.pos.y);
  }

  public destroy() {
    this.graphics.destroy();
  }

  public select(first = false) {
    if (this.selected) {
      return;
    }

    emitter.emit<SelectPointEvent>(Event.SelectPoint, { point: this, first });

    this.selected = true;
    this.graphics.zIndex = 1;

    this.outline = new PIXI.Graphics();
    this.outline.lineStyle(2, 0xffffff);
    this.outline.drawCircle(0, 0, SELECTED_SIZE * 0.85);
    this.outline.alpha = 0;
    this.outline.endFill();
    this.outline.zIndex = 2;

    this.graphics.addChild(this.outline);

    TweenMax.to(this.graphics, SELECTED_TWEEN_TIME, {
      alpha: 1,
      width: SELECTED_SIZE,
      height: SELECTED_SIZE,
      onComplete: () => {
        this.graphics.hitArea = new PIXI.Circle(0, 0, SELECTED_HIT_AREA);
      }
    });
    TweenMax.to(this.outline, SELECTED_TWEEN_TIME, {
      alpha: 1
    });
  }

  public deselect(fromOtherSelection = false) {
    if (!this.selected) {
      return;
    }

    this.graphics.alpha = this.opacity;
    this.graphics.zIndex = 0;

    TweenMax.to(this.graphics, SELECTED_TWEEN_TIME, {
      alpha: this.opacity,
      width: this.radius,
      height: this.radius,
      onComplete: () => {
        this.selected = false;
        this.graphics.hitArea = new PIXI.Circle(0, 0, POINT_HIT_AREA);

        if (this.selected) {
          this.graphics.removeChildAt(0);
        }
      }
    });
    TweenMax.to(this.outline, SELECTED_TWEEN_TIME, {
      alpha: 0
    });

    if (!fromOtherSelection) {
      emitter.emit<SelectPointEvent>(Event.SelectPoint, null);
    }
  }

  public show(visible = true) {
    this.visible = visible;
    this.setInteractive(this.visible);
    TweenMax.to(this.graphics, 0.5, { alpha: this.visible ? this.opacity : 0, ease: Power2.easeOut });
  }

  private setInteractive(value = true) {
    this.graphics.interactive = value;
    this.graphics.buttonMode = value;
  }

  private fadeAnimation(duration = FADE_DURATION) {
    return TweenMax.to(this.graphics, duration, {
      width: this.radius,
      height: this.radius,
      alpha: this.isPersonalMelati ? 1 : this.opacity
    });
  }

  private flyAnimation(duration = SHOOT_DURATION) {
    TweenMax.to(this.graphics, duration, { width: this.radius, height: this.radius });
    if (this.isPersonalMelati && this.newMelati) {
      return TweenMax.fromTo(
        this.graphics.position,
        duration,
        { x: 0, y: 0 },
        { x: this.targetPos.x, y: this.targetPos.y }
      );
    }
    return TweenMax.to(this.graphics.position, duration, {
      motionPath: [
        { x: this.spawnPos.x, y: this.spawnPos.y },
        { x: this.targetPos.x - 250, y: Math.min(innerHeight, this.targetPos.y + innerHeight / 4) },
        { x: this.targetPos.x, y: this.targetPos.y }
      ],
      ease: Power4.easeOut
    });
  }

  private getRadius() {
    if (this.isPersonalMelati) return 40;
    switch (this.data.type) {
      case IndexType.Message:
        return 13;
      case IndexType.Tag:
        return 17;
      case IndexType.Story:
        return 23;
    }
  }

  private getRandomOpacity() {
    return random(MIN_POINT_OPACITY, MAX_POINT_OPACITY);
  }
}

export { Point };
