
import { defineComponent, nextTick, PropType, ref } from 'vue';

import ContentDetail from '@/components/Content/ContentDetail.vue';
import TextBlock from '@/components/Content/TextBlock.vue';

import { StoryIndex } from '@functions/model/firestore';
import { contentfulService } from '@/services';
import { StoryResponse } from '@functions/model/content';
import { useMeta } from '@/store/useMeta';
import { getIndexRoutePath } from '@/router';

export default defineComponent({
  components: {
    ContentDetail,
    TextBlock
  },
  emits: ['open-detail'],
  props: {
    story: {
      type: Object as PropType<StoryIndex>,
      required: true
    },
    initOpen: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    if (props.first) {
      useMeta({
        title: props.story.metaTitle || props.story.title,
        description: props.story.metaDescription || props.story.introText,
        canonical: `/${getIndexRoutePath(props.story.type)}/${props.story.id}`
      });
    }

    const fullStory = ref<StoryResponse | null>(null);
    const loading = ref(true);

    const onOpenContent = async () => {
      fullStory.value = await contentfulService.getStoryById(props.story.id);
      loading.value = false;

      await nextTick();
    };

    return {
      fullStory,
      onOpenContent,
      loading
    };
  }
});
