import gsap, { Power4, Power1, TimelineMax } from 'gsap';

import { TimelineItem, TimelinePeriod } from '@functions/model/timeline';
import { Vector } from '@/utils/vector';

export type TimelineEvent = 'init' | 'period-enter' | 'items-enter';

/*
  Timeline tween timing variables
 */
const IN_DELAY_TIME = 0.5; // // Delay time before initial tween in seconds
const IN_TIME = 0.75; // Time to tween in initially in seconds
const IN_DISTANCE = 8; // Distance in seconds to travel through the timeline initially
const PERIOD_IN_TIME = 3; // Time to tween in period title/year in seconds
const PERIOD_OUT_TIME = 2; // Time to tween out period title/year in seconds
const PERIOD_SCALE_FROM_MULTIPLIER = 0.2; // Multiplier for the initial scale from value
const PERIOD_SCALE_IN_MULTIPLIER = 0.5; // Multiplier for the scale in value
const PERIOD_SCALE_OUT_MULTIPLIER = 1; // Multiplier for the scale out value
const PERIOD_SLIDE_TIME = 0.5; // Time to slide through the timeline on period slide change in seconds
const PERIOD_SLIDE_TWEEN_DISTANCE = 3; // Distance in seconds to travel through the timeline on period slide change
const ITEM_IN_OUT_TIME = 20; // Time to tween in/out a timeline item in seconds
const ITEM_OFFSET_TIME = 3; // Time between each timeline item tween in seconds
const ITEM_POSITION_MULTIPLIER = 5; // Multiplier for the x/y value of the timeline item position to change in the tween
const ITEM_SCALE_MULTIPLIER = 1; // Multiplier for the scale value of the timeline item to change in the tween

export class AnimatedTimeline {
  public tl: TimelineMax;

  private periods: TimelinePeriod[] = [];
  private listeners: Partial<Record<TimelineEvent, Function[]>> = {};
  private created = false;

  constructor() {
    this.tl = gsap.timeline({ paused: true });
  }

  public init(periods: TimelinePeriod[]) {
    this.periods = periods;

    const offsetX = Math.max(10, window.innerWidth / 20);
    const offsetY = Math.max(10, window.innerHeight / 20);

    const leftPositions = [-offsetY, offsetY, 0];
    const rightPositions = leftPositions.slice().reverse();

    const positionedPeriods = this.periods.map(period => {
      return {
        ...period,
        content: period.content.map((item, itemIndex) => {
          const left = itemIndex % 2 === 0;
          const position = new Vector(
            left ? -offsetX : offsetX,
            left ? leftPositions[itemIndex % 3] : rightPositions[itemIndex % 3]
          );

          return { ...item, position };
        })
      };
    });

    positionedPeriods.forEach((period, periodIndex) => {
      const periodId = createTimelinePeriodId(period.id);

      // Period-in animation
      const periodInTween = {
        scale: PERIOD_SCALE_IN_MULTIPLIER,
        autoAlpha: 0.8,
        duration: PERIOD_IN_TIME
      };
      periodIndex === 0
        ? this.tl.set(periodId, periodInTween)
        : this.tl.fromTo(periodId, { scale: PERIOD_SCALE_FROM_MULTIPLIER }, periodInTween);

      this.tl.addLabel(periodId);
      this.tl.call(() => this.handleEvent('period-enter', periodIndex));

      // Period item animations
      const periodItemStart = `${periodId}-items`;
      const itemIds = period.content.map(item => createTimelineItemId(period.id, item.id));

      this.tl.call(() => this.handleEvent('items-enter', itemIds));
      this.tl.addLabel(periodItemStart);

      period.content.forEach((item: TimelineItem & { position: Vector }, itemIndex) => {
        const itemId = createTimelineItemId(period.id, item.id);
        this.tl.addLabel(itemId, periodItemStart + '+=' + (ITEM_OFFSET_TIME * itemIndex + ITEM_IN_OUT_TIME / 2));
        this.tl.to(
          itemId,
          {
            autoAlpha: 1,
            duration: ITEM_IN_OUT_TIME / 2,
            ease: Power4.easeInOut,
            repeat: 1,
            yoyo: true,
            delay: ITEM_OFFSET_TIME * itemIndex
          },
          periodItemStart
        );
        const toX = item.position.x * ITEM_POSITION_MULTIPLIER;
        const toY =
          item.position.y > 0
            ? Math.min(
                (window.innerHeight / 150) * Math.pow(window.innerHeight / 150, 2),
                item.position.y * ITEM_POSITION_MULTIPLIER
              )
            : Math.max(
                (-window.innerHeight / 150) * Math.pow(window.innerHeight / 150, 2),
                item.position.y * ITEM_POSITION_MULTIPLIER
              );
        this.tl.fromTo(
          itemId,
          {
            x: item.position.x,
            y: item.position.y,
            scale: 0
          },
          {
            x: toX,
            y: toY,
            scale: ITEM_SCALE_MULTIPLIER,
            duration: ITEM_IN_OUT_TIME,
            delay: ITEM_OFFSET_TIME * itemIndex
          },
          periodItemStart
        );
      });

      // Period-out animation
      this.tl.to(periodId, {
        scale: PERIOD_SCALE_OUT_MULTIPLIER,
        autoAlpha: 0,
        duration: PERIOD_OUT_TIME
      });
      this.tl.call(() => this.handleEvent('items-enter', itemIds));
    });

    if (!this.created) {
      this.tweenIn();
    }
  }

  public tweenToPeriod(periodIndex: number) {
    return new Promise(resolve => {
      gsap.to('.timeline-container', { opacity: 0, duration: PERIOD_SLIDE_TIME });
      this.tl
        .tweenFromTo(this.tl.time(), this.tl.time() + PERIOD_SLIDE_TWEEN_DISTANCE, { duration: PERIOD_SLIDE_TIME })
        .eventCallback('onComplete', () => {
          this.tl.seek(createTimelinePeriodId(this.periods[periodIndex].id));
          gsap.to('.timeline-container', { opacity: 1, duration: PERIOD_SLIDE_TIME });
          this.tl
            .tweenFromTo(this.tl.time() - PERIOD_SLIDE_TWEEN_DISTANCE, this.tl.time(), {
              duration: PERIOD_SLIDE_TIME
            })
            .eventCallback('onComplete', resolve);
        });
    });
  }

  public on(event: TimelineEvent, callback: Function) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event]?.push(callback);
  }

  public off(event: TimelineEvent, callback: Function) {
    const target = this.listeners[event];
    const targetCallback = target?.findIndex(cb => cb === callback);

    if (targetCallback && targetCallback > -1) {
      target?.splice(targetCallback, 1);
    }
  }

  public resize() {
    const progress = this.tl.progress();

    this.tl.seek(0);
    this.tl.kill();
    this.tl = gsap.timeline({ paused: true });
    this.init(this.periods);

    this.tl.progress(progress);
  }

  private tweenIn() {
    gsap.to('.timeline-container', { opacity: 1, duration: IN_TIME, ease: Power1.easeOut, delay: IN_DELAY_TIME });
    this.tl
      .tweenFromTo(this.tl.time(), this.tl.time() + IN_DISTANCE, {
        duration: IN_TIME,
        delay: IN_DELAY_TIME,
        ease: Power1.easeOut
      })
      .eventCallback('onComplete', () => {
        this.created = true;
        this.handleEvent('init');
      });
  }

  private handleEvent(event: TimelineEvent, params?: any) {
    const target = this.listeners[event];
    if (target) {
      for (const listener of target) listener(params);
    }
  }
}

export function getPeriodLabel(period: TimelinePeriod) {
  const { start, end } = period;
  return end ? `${start}-${Number(end) ? end.toString().substring(2) : end}` : start.toString();
}

export function createTimelinePeriodId(periodId: string) {
  return `#period-${periodId}`;
}

export function createTimelineItemId(periodId: string, itemId: string) {
  return `#item-${periodId}-${itemId}`;
}
