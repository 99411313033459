import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    default: _withCtx(() => [
      (_ctx.hasTags)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.output, (o, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              (o.tag)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: 'Tag', params: { id: o.id } }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(o.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(o.text), 1)
                  ], 64))
            ], 64))
          }), 128))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ], 64))
    ]),
    _: 1
  }, 16))
}