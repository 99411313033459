import { ref } from 'vue';

const prefersReducedMotion = ref<boolean>(window.matchMedia('(prefers-reduced-motion: reduce)').matches);

export function usePreferences() {
  function setPrefersReducedMotion(value: boolean) {
    prefersReducedMotion.value = value;
  }

  return {
    prefersReducedMotion,
    setPrefersReducedMotion
  };
}
