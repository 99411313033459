
import { Melati } from '@/model/data';
import { computed, defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<Melati>,
      required: true
    },
    disable: {
      type: Boolean
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const focus = ref<null | HTMLInputElement>(null);

    const newMelatiData = computed({
      get: () => {
        return props.modelValue;
      },
      set: (newValue: Melati) => {
        emit('update: modelValue', newValue);
      }
    });

    watch(
      () => props.active,
      async current => {
        if (!document.body.classList.contains('floating-focus-enabled')) return;
        setTimeout(() => {
          if (current) focus.value?.focus();
        }, 1000);
      }
    );

    return {
      newMelatiData,
      focus
    };
  }
});
