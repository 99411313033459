import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44881088"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text"
}
const _hoisted_2 = {
  key: 0,
  class: "upper"
}
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = {
  key: 0,
  class: "detail-wrapper"
}
const _hoisted_5 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentMedia = _resolveComponent("ContentMedia")!
  const _component_TaggedMarkdown = _resolveComponent("TaggedMarkdown")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ShareButton = _resolveComponent("ShareButton")!
  const _component_RelatedContent = _resolveComponent("RelatedContent")!
  const _component_LeaveMelati = _resolveComponent("LeaveMelati")!

  return (_ctx.content)
    ? (_openBlock(), _createElementBlock("article", {
        key: 0,
        class: _normalizeClass(["detail", { open: _ctx.detailIsOpen }]),
        style: _normalizeStyle(_ctx.containerStyle),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openContent && _ctx.openContent(...args)))
      }, [
        _createVNode(_component_ContentMedia, {
          image: _ctx.content.image,
          video: _ctx.content.video,
          onPlay: _ctx.onPlay,
          open: _ctx.detailIsOpen
        }, null, 8, ["image", "video", "onPlay", "open"]),
        (!_ctx.detailIsOpen && !_ctx.initOpen)
          ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
              (_ctx.content.person)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.content.person), 1))
                : _createCommentVNode("", true),
              _createElementVNode("h2", null, _toDisplayString(_ctx.content.title), 1),
              _createVNode(_component_TaggedMarkdown, {
                class: "intro",
                text: _ctx.content.introText,
                tags: _ctx.content.tags
              }, null, 8, ["text", "tags"]),
              _createElementVNode("button", {
                class: "open-content",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openContent && _ctx.openContent(...args)))
              }, [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_Icon, { name: "expand" })
                ]),
                _createTextVNode(" Open dit " + _toDisplayString(_ctx.contentLabel), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["detail-container", { hide: !_ctx.detailIsOpen }])
        }, [
          (_ctx.detailIsOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ShareButton, {
                  class: "share-content",
                  url: _ctx.contentUrl,
                  reverse: ""
                }, null, 8, ["url"]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                (_ctx.related)
                  ? (_openBlock(), _createBlock(_component_RelatedContent, {
                      key: 0,
                      related: _ctx.related
                    }, null, 8, ["related"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_LeaveMelati)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 6))
    : _createCommentVNode("", true)
}