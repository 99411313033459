
import { defineComponent, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import gsap from 'gsap';
import FloatingFocus from '@q42/floating-focus-a11y';

import Menu from '@/components/Menu.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import Meta from '@/components/Meta.vue';
import CookieBar from '@/components/Cookie/CookieBar.vue';

import { RouteName } from '@/router';
import { HomePageState, useHomePageState } from '@/store/useHomePageState';
import { usePageTransition } from '@/store/usePageTransition';
import { useInterfaceSettings } from '@/store/useInterfaceSettings';
import { useCustomRoute } from '@/store/useCustomRoute';
import LazyLoadInstance from '@/utils/lazyload';
import { initAnalytics } from '@/utils/analytics';
import { loadMarketingScripts } from '@/utils/marketing-scripts';
import { useEnv } from '@/store/useEnv';
import { useCookieConsent } from '@/store/useCookieConsent';

import '@/utils/view-height-helper';
import { getSessionStorageItem, SessionStorageKey } from './utils/session-storage';

const ENTER_PAGE_TRANSITION_DURATION = 1;
const LEAVE_PAGE_TRANSITION_DURATION = 0.75;

export default defineComponent({
  components: {
    Menu,
    BottomMenu,
    Meta,
    CookieBar
  },
  setup() {
    const route = useRoute();
    const { isDevelopment } = useEnv();
    const { setCurrentRouteName, setCurrentRoutePath } = useCustomRoute();
    const { setHomePageState } = useHomePageState();
    const { setShowBottomMenu, setShowTotals } = useInterfaceSettings();
    const { handleLeaveTransitions } = usePageTransition();
    const { cookieConsent } = useCookieConsent();

    let initialized = false;
    let leaveTransitionPromise: Promise<void>;
    let resolveLeaveTransitionPromise: () => void;

    if (cookieConsent.value) {
      loadMarketingScripts();
    }

    onMounted(() => {
      initAnalytics();

      new FloatingFocus();
    });

    const enterTransition = async (el: HTMLElement, done: () => void) => {
      el.classList.add('active-page-transition');
      el.style.zIndex = '-1';

      await leaveTransitionPromise;

      el.style.removeProperty('z-index');
      gsap
        .fromTo(
          el,
          {
            opacity: 0
          },
          {
            duration: ENTER_PAGE_TRANSITION_DURATION,
            opacity: 1
          }
        )
        .eventCallback('onComplete', () => {
          document.body.classList.remove('body-overflow');
          done();
        });
    };

    const afterEnterTransition = (el: HTMLElement) => {
      el.classList.remove('active-page-transition');
      LazyLoadInstance.lazyload.update();
    };

    const leaveTransition = async (el: HTMLElement, done: () => void) => {
      document.body.classList.add('body-overflow');
      el.classList.add('active-page-transition');

      leaveTransitionPromise = new Promise(resolve => (resolveLeaveTransitionPromise = resolve));
      await handleLeaveTransitions();
      resolveLeaveTransitionPromise();

      gsap
        .fromTo(
          el,
          {},
          {
            duration: LEAVE_PAGE_TRANSITION_DURATION,
            opacity: 0
          }
        )
        .eventCallback('onComplete', done);
    };

    const afterLeaveTransition = (el: HTMLElement) => {
      el.classList.remove('active-page-transition');
    };

    watch(
      () => route.name,
      val => {
        const introSeen = getSessionStorageItem(SessionStorageKey.IntroSeen);
        if (introSeen || (!initialized && val !== RouteName.Home)) {
          setHomePageState(HomePageState.Melati);
          setShowBottomMenu(true);
          setShowTotals(true);

          initialized = true;
        }

        setCurrentRouteName((val as RouteName) || '');
      }
    );

    watch(() => route.path, setCurrentRoutePath);

    return {
      enterTransition,
      afterEnterTransition,
      leaveTransition,
      afterLeaveTransition,
      isDevelopment
    };
  }
});
