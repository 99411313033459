
import { computed, defineComponent } from 'vue';
import { useMeta } from '@/store/useMeta';
import { useEnv } from '@/store/useEnv';

export default defineComponent({
  setup() {
    const { config, initialMetaConfig } = useMeta();
    const { isProduction } = useEnv();

    const title = computed(() =>
      config.value.titleSuffix && config.value.title !== initialMetaConfig.title
        ? `${config.value.title} | ${config.value.titleSuffix}`
        : config.value.title
    );
    const canonical = computed(() =>
      config.value.canonical ? `${window.location.origin}${config.value.canonical}` : undefined
    );

    const metaTags = computed(() =>
      [
        {
          name: 'description',
          content: config.value.description
        }
      ].filter(tag => tag.content)
    );

    return {
      config,
      title,
      canonical,
      metaTags,
      isProduction
    };
  }
});
