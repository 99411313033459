import { Directive } from 'vue';

export const clickOutside: Directive = {
  beforeMount(e, binding) {
    e.clickOutsideEvent = (event: MouseEvent) => {
      const { action, ignoredEl } = binding.value;
      if (e && !e.contains(event.target as Node) && !event.defaultPrevented && !ignoredEl.contains(event.target)) {
        action();
      }
    };
    document.addEventListener('click', e.clickOutsideEvent, { capture: true });
  },
  unmounted(e) {
    document.removeEventListener('click', e.clickOutsideEvent, { capture: true });
  }
};
