
import { computed, defineComponent, PropType, ref } from 'vue';

import { Video } from '@functions/model/content';
import { Image } from '@functions/model/firestore';
import { useScreen } from '@/store/useScreen';

export default defineComponent({
  props: {
    image: {
      type: Object as PropType<Image>,
      required: true
    },
    video: {
      type: Object as PropType<Video>
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const playingVideo = ref(false);
    const { height } = useScreen();

    const containerHeightPx = computed(() => `${props.open ? height.value * 0.7 : height.value}px`);
    const videoUrl = computed(() => {
      if (!props.video) return;

      return props.video.videoType === 'youtube'
        ? `https://www.youtube-nocookie.com/embed/${props.video.videoId}?wmode=transparent&autoplay=1&modestbranding=1&rel=0&enablejsapi=1`
        : `https://player.vimeo.com/video/${props.video.videoId}?transparent=false&color=ffffff&autoplay=1&title=0&byline=0&portrait=0&dnt=1`;
    });

    const playVideo = async () => {
      context.emit('play');
      playingVideo.value = true;
    };

    return {
      playingVideo,
      videoUrl,
      containerHeightPx,
      playVideo
    };
  }
});
