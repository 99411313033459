export function getImgUrl(
  url: string,
  width: number | null = null,
  height: number | null = null,
  webp = false,
  quality = 90
) {
  let qs = `fm=${webp ? 'webp' : 'jpg'}&q=${quality}`;

  if (width) {
    qs += `&w=${width}`;
  }

  if (height) {
    qs += `&h=${height}`;
  }

  return `${url}?${qs}`;
}
